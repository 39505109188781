import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const RecordExport = withAsyncComponent(() =>
  import('@/pages/crmExperiment/record/Index')
);
const ProductManager = withAsyncComponent(() =>
  import('@/pages/crmExperiment/product/Index')
);
const ProductManagerEdit = withAsyncComponent(() =>
import('@/pages/crmExperiment/product/Detail')
);

const experimentRoute = [
  {
    path: '/experiment',
    name: '对比试验',
    key: 'experiment',
    icon: 'experiment',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        name: '对比试验记录',
        path: '/experiment/report',
        icon: 'profile',
        exact: true,
        key: 'experimentExport',
        Component: RecordExport,
      },
      {
        name: '对比试验商品',
        path: '/experiment/product',
        icon: 'gold',
        exact: true,
        key: 'experimentProduct',
        Component: ProductManager,
      },{
        name: '对比试验商品编辑',
        path: '/experiment/product/edit/:id',
        exact: true,
        hideInMenu: true,
        key: 'experimentProductEdit',
        Component: ProductManagerEdit,
      }
    ],
  },
];

export default experimentRoute;
