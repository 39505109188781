import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Target = withAsyncComponent(() =>
  import('@/pages/target/Index')
);
const TargetEdit = withAsyncComponent(() =>
  import('@/pages/target/Detail')
);
const TargetRoute = [
  {
    path: '/crmtarget',
    name: '目标设定',
    key: 'crmTarget',
    icon: 'medicine-box',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/crmtarget',
        exact: true,
        key: 'crmTargetList',
        Component: Target,
        hideInMenu: true,
      },
      {
        path: '/crmtarget/edit/:id',
        exact: true,
        key: 'crmTargetEdit',
        Component: TargetEdit,
        hideInMenu: true,
      },
    ],
  },
]
export default TargetRoute;