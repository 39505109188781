/** 植保专家管理 **/
import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Vet = withAsyncComponent(() =>
  import('@/pages/vetadministration/vet/List')
); // 兽医列表
const VetDetail = withAsyncComponent(() =>
  import('@/pages/vetadministration/vet/Vetdetail')
); // 兽医详情、编辑
const Question = withAsyncComponent(() =>
  import('@/pages/question/List')
); // 问答列表

const PlantExpert = [
  {
    path: '/plantexpert',
    name: '植保专家管理',
    key: 'plantexpert',
    icon: 'insurance',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/plantexpert/vet',
        name: '专家管理',
        key: 'vet',
        icon: 'medicine-box',
        routes: [
          {
            path: '/plantexpert/vet',
            exact: true,
            key: 'vetlist',
            Component: Vet,
            hideInMenu: true,
          },
          {
            path: '/plantexpert/vet/add',
            name: '专家新增',
            key: 'vetAdd',
            Component: VetDetail,
            hideInMenu: true,
          },
          {
            path: '/plantexpert/vet/edit/:id',
            name: '专家编辑',
            key: 'vetEdit',
            Component: VetDetail,
            hideInMenu: true,
          },
          {
            path: '/plantexpert/vet/detail/:id/:type',
            name: '专家详情',
            key: 'vetDetail',
            Component: VetDetail,
            hideInMenu: true,
          },
        ],
      },
      {
        path: '/plantexpert/question',
        name: '问答管理',
        key: 'question',
        icon: 'question-circle',
        routes: [
          {
            path: '/plantexpert/question',
            exact: true,
            key: 'questionList',
            Component: Question,
            hideInMenu: true,
          },
        ]
      },
    ],
  },
];
export { PlantExpert };
