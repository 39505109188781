import send from '../../util/send';
import { PROMOTEPATH } from '../../config/config';
import { toHumpObj, toLineObj } from '../../util/convert';

// 获取文章列表
export function getArticleListApi(path, params) {
  return send({ url: path + '/admin/media/search', params }).then((res) => {
    if (res.data.error === 0) {
      let { data } = res.data;
      data.list.map((item) => {
        return (item.status = Number(item.status));
      });
      return data;
    } else {
      return [];
    }
  });
}

// 文章列表撤回
export function getArticleToWithdraw(path, data) {
  return send({ url: path + '/admin/media/recall', method: 'POST', data });
}

// 文章列表发布
export function getArticleRelease(path, data) {
  return send({ url: path + '/admin/media/publish', method: 'POST', data });
}

// 文章列表删除
export function getArticleDele(path, data) {
  return send({ url: path + '/admin/media/delete', method: 'POST', data });
}

// 文章列表是否置顶
export function getArticlePlaceTop(path, data) {
  return send({ url: path + '/admin/media/update', method: 'POST', data });
}

// 文章列表通过标题查看详情
export function getArticleDetail(path, params) {
  return send({ url: path + '/admin/media/detail', params });
}

// 文章列表获取分类
export function getArticleClass(path, params) {
  return send({ url: path + '/admin/media/types', params });
  // return send({ url: path + '/admin/media/types', params }).then((res) => {
  //   if (res.data.error === 0) {
  //     let { data } = res.data;
  //     data.filter(
  //       (item) => item.name.indexOf('热门') < 0 && item.name.indexOf('视频') < 0
  //     );
  //     return data || []
  //   }
  // });
}

// 文章列表获取发布大区数据(次接口是从后台账号管理的详情接口获取的 id = 登录进来的词人的uid)    !!! 后台账号管理详情接口
export function getArticleRegional(params) {
  return send({ url: '/api/backend/v1/admin', params });
}

// 文章、视频获取详情接口先数据详情
export function getDetailApi(params) {
  return new Promise((resolve, reject) => {
    send({
      url: `${PROMOTEPATH}/admin/media/media`,
      params: toLineObj(params),
    })
      .then((res) => {
        const { error, data, msg } = res.data;
        if (error === 0) {
          resolve(toHumpObj(data));
        } else {
          reject(msg);
        }
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}

// 文章编辑发布
export function getArticleEditPublish(path, url, method, data) {
  return send({ url: path + url, method, data });
}

// 获取分类
export function getMediaTypeApi(params) {
  return new Promise((resolve, reject) => {
    send({
      url: `${PROMOTEPATH}/admin/media/types`,
      params: toLineObj(params),
    })
      .then((res) => {
        const { error, data, msg } = res.data;
        if (error === 0) {
          resolve(toHumpObj(data));
        } else {
          reject(msg);
        }
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}

// 获取标签
export function getPostTag(path, params) {
  return send({ url: path + '/admin/media/tags', params });
}

export function getSpareArticleListApi(params) {
  return new Promise((resolve, reject) => {
    send({
      url: `${PROMOTEPATH}/admin/spider-media/get-list`,
      params: toLineObj(params),
    })
      .then((res) => {
        const { error, data, msg } = res.data;
        if (error === 0) {
          resolve(toHumpObj(data));
        } else {
          reject(msg);
        }
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}
export function getTagListApi(params) {
  return new Promise((resolve, reject) => {
    send({
      url: `${PROMOTEPATH}/admin/spider-sensitive-word/get-list`,
      params: toLineObj(params),
    })
      .then((res) => {
        const { error, data, msg } = res.data;
        if (error === 0) {
          resolve(toHumpObj(data));
        } else {
          reject(msg);
        }
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}
export function deleteTagApi(params) {
  return new Promise((resolve, reject) => {
    send({
      url: `${PROMOTEPATH}/admin/spider-sensitive-word/del`,
      params: toLineObj(params),
    })
      .then((res) => {
        const { error, msg } = res.data;
        if (error === 0) {
          resolve();
        } else {
          reject(msg);
        }
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}
export function addTagApi(params) {
  return new Promise((resolve, reject) => {
    send({
      url: `${PROMOTEPATH}/admin/spider-sensitive-word/add`,
      params: toLineObj(params),
    })
      .then((res) => {
        const { error, data, msg } = res.data;
        if (error === 0) {
          resolve(data);
        } else {
          reject(msg);
        }
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}
export function publishApi(params) {
  return new Promise((resolve, reject) => {
    send({
      url: `${PROMOTEPATH}/admin/spider-media/publish`,
      params: toLineObj(params),
    })
      .then((res) => {
        const { error, msg } = res.data;
        if (error === 0) {
          resolve();
        } else {
          reject(msg);
        }
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}
export function deleteSpareArticleApi(params) {
  return new Promise((resolve, reject) => {
    send({
      url: `${PROMOTEPATH}/admin/spider-media/del`,
      params: toLineObj(params),
    })
      .then((res) => {
        const { error, msg } = res.data;
        if (error === 0) {
          resolve();
        } else {
          reject(msg);
        }
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}
export function getSpareArticleContentApi(params) {
  return new Promise((resolve, reject) => {
    send({
      url: `${PROMOTEPATH}/admin/spider-media/preview`,
      params: toLineObj(params),
    })
      .then((res) => {
        const { error, data, msg } = res.data;
        if (error === 0) {
          resolve(data && data.media && data.media.content);
        } else {
          reject(msg);
        }
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}

export async function getBreedTypeListApi() {
  const res = await send({
    url: `${PROMOTEPATH}/admin/media/get-type`,
  });
  const { error, data, msg } = res.data;
  if (error === 0) {
    return Object.keys(data).map(key=>{
      return {
        label: data[key],
        value: key
      }
    });
  } else {
    return Promise.reject(msg);
  }
}