import withAsyncComponent from '@/util/withAsyncComponent';
import withInitListener from '@/util/withInitListener';
import { withRouter } from 'react-router-dom';
import { medicineRoutes } from './routes/medicineRoute';
import { financialRoute } from './routes/financialRoute';
import { feedCommodityRoute } from './routes/feedCommodityRoute';
import { GaveBag } from './routes/giftBagRoute';
import { Logistics } from './routes/logisticsRoute'; // 物流司机
import { Superset } from './routes/supersetRoute'; // 数据看板
import { PigTrade } from './routes/pigtradeRoute';
import { Premix } from './routes/premixRoute';
import { vetRouter } from './routes/vetRouter';
import { semenRoutes } from './routes/semenRoute'; // 精液

import { Content } from './routes/contentRoute'; // 内容管理
import { PlantExpert } from './routes/plantExpertRoute';// 植保专家管理
import { FeedbackManage } from './routes/feedbackRoute';// 意见反馈管理
import { Systems } from './routes/systemsRoute';// 系统管理

import adRoute from './routes/adRoute';
import posterRoute from './routes/posterRoute';
import systemRoute from './routes/systemRoute';
import visitRoute from './routes/visitRoute';
import feedUncooperateCustomerRoute from './routes/feedUncooperateCustomerRoute';
import digitalSignageRoute from './routes/digitalSignageRoute';
import targetRoute from './routes/targetRoute';
import SpareArticleList from '../pages/article/SpareArticleList';
import Depart from '../pages/depart/Index';
import reportExportRoute from './routes/reporetExportRoute';
import marketCustomerRoute from './routes/marketCustomerRoute';
import experimentRoute from './routes/experimentRoute';
import ThreePartySystemContainer from '../pages/threePartySystemContainer/Index';
import crmTrackRoute from './routes/crmTrackRoute';

import { goodsAndOrderService } from './routes/goodsAndOrderServiceRoute'
import { memberManagement } from './routes/memberManagementRoute'
import crmReportRoute from './routes/crmReportRoute';
import animalHealthRoute from './routes/animalHealthRoute';// 动保


const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Login = withAsyncComponent(() => import('@/pages/login/Login'));
const Slaughter = withAsyncComponent(() => import('@/pages/slaughter/Index'));
const SlaughterDetail = withAsyncComponent(() => import('@/pages/slaughter/Detail'));
const Price = withAsyncComponent(() => import('@/pages/price/List')); // 价格管理
const OrderList = withAsyncComponent(() =>
  import('@/pages/pricematerial/List')
); // 订单列表
const OrderDetail = withAsyncComponent(() =>
  import('@/pages/pricematerial/Pricematerialdetail')
);
//订单修改
const OrderModify = withAsyncComponent(() =>
  import('@/pages/pricematerial/OrderModify')
);
// 订单详情
const Relatedfarms = withAsyncComponent(() =>
  import('@/pages/relatedfarms/List')
); // 用户管理农场列表
const RelatedfarmsEdit = withAsyncComponent(() =>
  import('@/pages/relatedfarms/RelatedfarmsEdit')
); // 编辑用户管理农场
const Threshold = withAsyncComponent(() => import('@/pages/threshold/List')); // 农场指标阈值列表
const ThresholdEdit = withAsyncComponent(() =>
  import('@/pages/threshold/ThresholdEdit')
); // 编辑农场指标阈值
const Calculation = withAsyncComponent(() =>
  import('@/pages/calculation/List')
); // 生产指标计算设定

const Salesman = withAsyncComponent(() => import('@/pages/salesman/List')); // 销售员管理
const SalesmanEdit = withAsyncComponent(() =>
  import('@/pages/salesman/SalesmanEdit')
); // 销售员编辑
const FeedCustomer = withAsyncComponent(() =>
  import('@/pages/feedcustomer/List')
); // 饲料客户列表
const FeedCustomerDetail = withAsyncComponent(() =>
  import('@/pages/feedcustomer/FeedCustmerEdit')
); // 编辑饲料客户

const FeedCustomerCompanyEdit = withAsyncComponent(() =>
  import('@/pages/feedcustomer/CompanyEdit')
); // 编辑饲料客户公司设定

const Feed = withAsyncComponent(() => import('@/pages/feedrelationship/List')); // 种植与饲料厂关系设定
const FeeDetail = withAsyncComponent(() =>
  import('@/pages/feedrelationship/RelationshipEdit')
); // 新增/编辑种植与饲料厂关系设定
const OldDataSync = withAsyncComponent(() =>
  import('@/pages/datasyncold/List')
); // 数据同步-老
const DataSynchronous = withAsyncComponent(() =>
  import('@/pages/datasynchronous/List')
); // 数据同步
const AreasList = withAsyncComponent(() =>
  import('@/pages/relatedareas/RelatedAreas')
); // 用户绑定地区
const AreasDetail = withAsyncComponent(() =>
  import('@/pages/relatedareas/RelateDetail')
); // 编辑用户绑定地区
const MaintenanceList = withAsyncComponent(() =>
  import('@/pages/farmaintenance/FarMaintenance')
); // 农场维护列表
const FeedCompany = withAsyncComponent(() =>
  import('@/pages/feedcompany/FeedCompany')
); // 饲料公司
const companyDetail = withAsyncComponent(() =>
  import('@/pages/feedcompany/CompanyDetail')
); // 饲料公司
const ContractTemplateSetting = withAsyncComponent(() =>
  import('@/pages/feedcompany/ContractTemplateSetting')
); // 合同模板设定/合同模板查看修改
const FeedCustomerContract = withAsyncComponent(() =>
  import('@/pages/feedCustomerContract/List')
); // 饲料客户合同管理列表
const FeedContractEdit = withAsyncComponent(() =>
  import('@/pages/feedCustomerContract/ContractEdit')
); // 饲料客户合同-合同编辑
const FeedAuthorizationManagement = withAsyncComponent(() =>
  import('@/pages/feedCustomerContract/AuthorizationManagement')
); // 饲料客户合同-授权管理
const Pay = withAsyncComponent(() => import('@/pages/payconfig/List')); // 支付列表
const payConfig = withAsyncComponent(() => import('@/pages/payconfig/Config')); // 支付配置
const Bill = withAsyncComponent(() => import('@/pages/bill/List')); // 账单管理
const BillDetail = withAsyncComponent(() => import('@/pages/bill/Detail')); // 账单管理详情
const UserAudit = withAsyncComponent(() => import('@/pages/useraudit')); // 用户审计
const Home = withAsyncComponent(() => import('@/pages/home/List')); // 首页
const PassWord = withAsyncComponent(() => import('@/pages/login/PassWord')); // 修改密码
const OperPassWord = withAsyncComponent(() =>
  import('@/pages/login/OperPassWord')
); // 修改密码
const Message = withAsyncComponent(() => import('@/pages/message/List')); // 修改密码

const NotFound = withAsyncComponent(() => import('@/pages/notFound/NotFound'));
//消息推送
const MessagePush = withAsyncComponent(() =>
  import('@/pages/messagePush/List')
);
const MessagePushDetail = withAsyncComponent(() =>
  import('@/pages/messagePush/Detail')
);
// 竞价过程报表
const Biddingreport = withAsyncComponent(() =>
  import('@/pages/biddingreport/Biddingreport')
);
const ApplicationList = withAsyncComponent(() =>
  import('@/pages/applicationForm')
); // 合作申请单查询
const ApplicationDetail = withAsyncComponent(() =>
  import('@/pages/applicationForm/detail')
); // 合作申请单详情
const LiveBroadcast = withAsyncComponent(() =>
  import('@/pages/liveBroadcast/List.tsx')
); // 直播列表
const LiveDetail = withAsyncComponent(() =>
  import('@/pages/liveBroadcast/LiveDetail')
); // 直播新增、编辑

const Download = withAsyncComponent(() => import('@/pages/download/Index')); // 直播新增、编辑


const Invite = withAsyncComponent(() =>
  import('@/pages/invite/List.tsx')
); // 拉新列表

const ExportClueData = withAsyncComponent(() =>
  import('@/pages/dataExport/clueData')
);

const ExportCrmData = withAsyncComponent(() =>
  import('@/pages/dataExport/CrmData')
);

const ExportCrmCustomData = withAsyncComponent(() =>
  import('@/pages/dataExport/crmCustomData/Index')
);
const ExportCrmCustomDataDetail = withAsyncComponent(() =>
  import('@/pages/dataExport/crmCustomData/Detail')
);

const UserInfo = withAsyncComponent(() =>
  import('@/pages/administration/AdminBaseInfo')
); // 用户账号信息

const Help = withAsyncComponent(() =>
  import('@/pages/help/Index')
); // 帮助文档



export const routes = [
  {
    path: '/login',
    key: 'login', //登录页需要特殊处理(不嵌套BasicLayout)，所以key不可修改
    Component: Login,
    hideInMenu: true,
  },
  {
    path: '/help',
    key: 'help',
    name: '使用帮助',
    Layout: BasicLayout,
    routes: [{ path: '/help', key: 'helpInfo', Component: Help }],
    hideInMenu: true,
  },
  {
    path: '/basic/useinfo',
    key: 'baisc',
    name: '账号信息',
    Layout: BasicLayout,
    hideInMenu: true,
    login: true,
    routes: [
      { path: '/basic/useinfo', key: 'basicUserInfo', Component: UserInfo },
    ],
  },
  {
    path: '/password',
    key: 'password',
    Layout: BasicLayout,
    login: true,
    hideInMenu: true,
    routes: [{ path: '/password', key: 'password', Component: PassWord }],
  },
  {
    path: '/operpassword',
    key: 'operpassword',
    name: '设置操作密码',
    Layout: BasicLayout,
    login: true,
    hideInMenu: true,
    routes: [
      {
        path: '/operpassword',
        key: 'operpassword',
        name: '设置操作密码',
        Component: OperPassWord,
      },
    ],
  },
  {
    path: '/message',
    key: 'message',
    name: '消息',
    Layout: BasicLayout,
    login: true,
    hideInMenu: true,
    routes: [
      {
        path: '/message',
        key: 'messageList',
        name: '消息',
        Component: Message,
      },
    ],
  },
  ...Content,
  ...PlantExpert,
  ...FeedbackManage,
  ...Systems,
  ...animalHealthRoute,
  {
    path: '/livebroadcast',
    name: '直播管理',
    key: 'livebroadcast',
    icon: 'play-square',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/livebroadcast',
        exact: true,
        key: 'livebroadcastlist',
        Component: LiveBroadcast,
        hideInMenu: true,
      },
      {
        path: '/livebroadcast/add',
        key: 'livebroadcastAdd',
        name: '直播发布',
        Component: LiveDetail,
        hideInMenu: true,
      },
      {
        path: '/livebroadcast/edit/:id',
        name: '编辑直播',
        key: 'livebroadcastEdit',
        Component: LiveDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/invite',
    name: '拉新管理',
    key: 'invite',
    icon: 'play-square',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/invite',
        exact: true,
        key: 'invite',
        Component: Invite,
        hideInMenu: true,
      },
    ],
  },
  ...posterRoute,
  {
    path: '/sparearticle',
    name: '备选文章库',
    key: 'sparearticle',
    icon: 'file-text',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/sparearticle',
        exact: true,
        key: 'sparearticleList',
        Component: SpareArticleList,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/application',
    name: '合作申请单查询',
    key: 'application',
    icon: 'form',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/application',
        exact: true,
        key: 'applicationlist',
        Component: ApplicationList,
        hideInMenu: true,
      },
      {
        path: '/application/edit/:id',
        name: '合作申请单详情',
        key: 'applicationdetail',
        Component: ApplicationDetail,
        hideInMenu: true,
      },
    ],
  },
  ...Logistics,
  ...GaveBag,
  ...feedCommodityRoute,
  ...adRoute,
  {
    path: '/depart',
    name: '离职管理',
    key: 'depart',
    icon: 'user',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/depart',
        exact: true,
        key: 'departIndex',
        Component: Depart,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/price',
    name: '价格管理',
    key: 'price',
    icon: 'pay-circle',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/price',
        exact: true,
        key: 'priceList',
        Component: Price,
        hideInMenu: true,
      },
    ],
  },
  // {
  //   path: '/slaughter',
  //   name: '自营送宰车辆管理',
  //   key: 'slaughter',
  //   icon: 'car',
  //   auth: false,
  //   login: true,
  //   Layout: BasicLayout,
  //   routes: [
  //     {
  //       path: '/slaughter',
  //       exact: true,
  //       key: 'slaughterList',
  //       Component: Slaughter,
  //       hideInMenu: true,
  //     },
  //     {
  //       path: '/slaughter/add',
  //       exact: true,
  //       key: 'slaughterAdd',
  //       Component: SlaughterDetail,
  //       hideInMenu: true,
  //     },
  //   ],
  // },
  {
    path: '/pricematerial',
    name: '订单查询',
    key: 'pricematerial',
    icon: 'file-text',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/pricematerial',
        exact: true,
        key: 'pricematerialList',
        Component: OrderList,
        hideInMenu: true,
      },
      {
        path: '/pricematerial/edit/:id',
        name: '订单详情',
        key: 'pricematerialDetail',
        Component: OrderDetail,
        hideInMenu: true,
      },
      {
        path: '/pricematerial/ordermodify/:orderNo',
        name: '订单修改',
        key: 'ordermodify',
        Component: OrderModify,
        hideInMenu: true,
        auth: true,
      },
    ],
  },
  {
    path: '/relatedfarms',
    name: '用户关联农场管理',
    key: 'relatedfarms',
    icon: 'compass',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/relatedfarms',
        exact: true,
        key: 'relatedfarmsList',
        Component: Relatedfarms,
        hideInMenu: true,
      },
      {
        path: '/relatedfarms/edit/:id',
        name: '编辑用户关联农场',
        key: 'relatedfarmsEdit',
        Component: RelatedfarmsEdit,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/threshold',
    name: '农场指标阈值管理',
    key: 'threshold',
    icon: 'home',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/threshold',
        exact: true,
        key: 'thresholdList',
        Component: Threshold,
        hideInMenu: true,
      },
      {
        path: '/threshold/edit/:id/:code',
        name: '编辑农场指标阈值',
        key: 'thresholdEdit',
        Component: ThresholdEdit,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/calculation',
    name: '生产指标计算设定',
    key: 'calculation',
    icon: 'area-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/calculation',
        exact: true,
        key: 'calculationList',
        Component: Calculation,
        hideInMenu: true,
      },
    ],
  },
  ...systemRoute,
  ...targetRoute,
  ...marketCustomerRoute,
  ...feedUncooperateCustomerRoute,
  ...visitRoute,
  {
    path: '/feedcustomer',
    name: '饲料客户管理',
    key: 'feedcustomer',
    icon: 'contacts',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/feedcustomer',
        exact: true,
        key: 'feedcustomerList',
        Component: FeedCustomer,
        hideInMenu: true,
      },
      {
        path: '/feedcustomer/edit/:id',
        name: '编辑饲料客户',
        key: 'feedcustomerEdit',
        Component: FeedCustomerDetail,
        hideInMenu: true,
      },
      {
        path: '/feedcustomer/companyedit/:id',
        name: '公司设定',
        key: 'feedCustomerCompanyEdit',
        Component: FeedCustomerCompanyEdit,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/exportcluedata',
    name: '线索池管理',
    key: 'ExportClueData',
    icon: 'download',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/exportcluedata',
        exact: true,
        key: 'ExportClueDataDetail',
        Component: ExportClueData,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/exportcrmdata',
    name: 'CRM统计报表',
    key: 'ExportCrmData',
    icon: 'download',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/exportcrmdata',
        exact: true,
        key: 'ExportCrmDataDetail',
        Component: ExportCrmData,
        hideInMenu: true,
      },
    ],
  },
  ...crmTrackRoute,
  // ...crmReportRoute,
  ...experimentRoute,
  {
    path: '/export/crm/custom',
    name: '线下业务报表',
    key: 'exportCrmCustomData',
    icon: 'download',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/export/crm/custom',
        exact: true,
        key: 'exportCrmCustomDataList',
        Component: ExportCrmCustomData,
        hideInMenu: true,
      },
      {
        path: '/export/crm/custom/edit/:id',
        key: 'exportCrmCustomDataEdit',
        Component: ExportCrmCustomDataDetail,
        hideInMenu: true,
      },
      {
        path: '/export/crm/custom/add',
        key: 'exportCrmCustomDataAdd',
        Component: ExportCrmCustomDataDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/salesman',
    name: '销售员管理',
    key: 'salesman',
    icon: 'user-add',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/salesman',
        exact: true,
        key: 'salesmanList',
        Component: Salesman,
        hideInMenu: true,
      },
      {
        path: '/salesman/edit/:id',
        name: '编辑销售员',
        key: 'salesmanEdit',
        Component: SalesmanEdit,
        hideInMenu: true,
      },
    ],
  },

  {
    path: '/feed',
    name: '种植与饲料厂关系设定',
    key: 'feed',
    icon: 'branches',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/feed',
        exact: true,
        key: 'feedList',
        Component: Feed,
        hideInMenu: true,
      },
      {
        path: '/feed/add',
        name: '新增种植与饲料厂关系设定',
        key: 'feedAdd',
        Component: FeeDetail,
        hideInMenu: true,
      },
      {
        path: '/feed/edit/:id',
        name: '编辑种植与饲料厂关系设定',
        key: 'feedEdit',
        Component: FeeDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/olddatasync',
    name: '数据同步',
    key: 'olddatasync',
    icon: 'stock',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/olddatasync',
        exact: true,
        key: 'olddatasynclist',
        Component: OldDataSync,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/datasynchronous',
    name: '数据同步(新)',
    key: 'datasynchronous',
    icon: 'stock',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/datasynchronous',
        exact: true,
        key: 'datasynchronousList',
        Component: DataSynchronous,
        hideInMenu: true,
      },
    ],
  },
  ...PigTrade,
  {
    path: '/areas',
    name: '用户绑定地区',
    key: 'areas',
    icon: 'environment',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/areas',
        exact: true,
        key: 'areasList',
        Component: AreasList,
        hideInMenu: true,
      },
      {
        path: '/areas/edit/:id',
        name: '编辑用户绑定地区',
        key: 'areasEdit',
        Component: AreasDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/maintenance',
    name: '农场维护',
    key: 'maintenance',
    icon: 'file-protect',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/maintenance',
        exact: true,
        key: 'maintenanceList',
        Component: MaintenanceList,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/feedcompany',
    name: '饲料公司管理',
    key: 'feedcompany',
    icon: 'switcher',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/feedcompany',
        exact: true,
        key: 'feedcompanylist',
        Component: FeedCompany,
        hideInMenu: true,
      },
      {
        path: '/feedcompany/add',
        name: '新增饲料公司',
        key: 'feedcompanyAdd',
        Component: companyDetail,
        hideInMenu: true,
      },
      {
        path: '/feedcompany/edit/:id',
        name: '编辑饲料公司',
        key: 'feedcompanyEdit',
        Component: companyDetail,
        hideInMenu: true,
      },
      {
        path: '/feedcompany/contracttemplatesetting/:companyCode/:type',
        name: '合同模板设定',
        key: 'feedcompanyEdit',
        Component: ContractTemplateSetting,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/feedcustomercontract',
    name: '饲料客户合同管理',
    key: 'feedcustomercontract',
    icon: 'folder-open',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/feedcustomercontract',
        exact: true,
        key: 'feedcustomercontractList',
        Component: FeedCustomerContract,
        hideInMenu: true,
      },
      {
        path: '/feedcustomercontract/contractedit/:companyCode/:ssRole/:cvCode/:pathType/:contractId/:uid',
        name: '合同编辑',
        key: 'contractedit',
        Component: FeedContractEdit,
        hideInMenu: true,
      },
      {
        path: '/feedcustomercontract/authorization/:contractId/:userType/:cvCode/:companyCode',
        name: '授权管理',
        key: 'authorization',
        Component: FeedAuthorizationManagement,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/pay',
    name: '支付配置',
    key: 'pay',
    icon: 'alipay',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/pay',
        exact: true,
        key: 'paylist',
        Component: Pay,
        hideInMenu: true,
      },
      {
        path: '/pay/config/:id',
        name: '支付配置',
        key: 'payconfig',
        Component: payConfig,
        hideInMenu: true,
      },
    ],
  },
  ...Premix,
  {
    path: '/bill',
    name: '账单管理',
    key: 'bill',
    icon: 'audit',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/bill',
        exact: true,
        key: 'billist',
        Component: Bill,
        hideInMenu: true,
      },
      {
        path: '/bill/detail/:id',
        name: '账单详情',
        key: 'billdetail',
        Component: BillDetail,
        hideInMenu: true,
      },
    ],
  },
  ...vetRouter,
  {
    path: '/messagepush',
    name: '通知推送',
    key: 'messagepush',
    icon: 'message',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/messagepush',
        exact: true,
        key: 'messagepushlist',
        Component: MessagePush,
        hideInMenu: true,
      },
      {
        path: '/messagepush/edit/:id',
        name: '编辑通知推送',
        key: 'messagepushedit',
        Component: MessagePushDetail,
        hideInMenu: true,
      },
      {
        path: '/messagepush/add',
        name: '新增通知推送',
        key: 'messagepushadd',
        Component: MessagePushDetail,
        hideInMenu: true,
      },
    ],
  },
  ...semenRoutes,
  ...medicineRoutes,

  {
    path: '/biddingreport',
    name: '竞价过程报表',
    key: 'biddingreport',
    icon: 'bar-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/biddingreport',
        exact: true,
        key: 'biddingreportList',
        Component: Biddingreport,
        hideInMenu: true,
      },
    ],
  },
  ...financialRoute,
  {
    path: '/useraudit',
    name: '用户审计',
    key: 'useraudit',
    icon: 'solution',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/useraudit',
        exact: true,
        key: 'userauditlist',
        Component: UserAudit,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/three/party/system',
    name: '嵌入三方系统',
    key: 'threePartySystem',
    icon: 'solution',
    hideInMenu: true,
    // auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/three/party/system',
        exact: true,
        key: 'threePartySystemContainer',
        Component: ThreePartySystemContainer,
        hideInMenu: true,
      },
    ],
  },
  ...digitalSignageRoute,
  ...Superset,
  reportExportRoute,
  // {
  //   path: '/download',
  //   name: '下载任务',
  //   key: 'download',
  //   icon: 'download',
  //   login: true,
  //   Layout: BasicLayout,
  //   routes: [
  //     {
  //       path: '/download',
  //       exact: true,
  //       key: 'downloadList',
  //       Component: Download,
  //       hideInMenu: true,
  //     },
  //   ],
  // },
  ...goodsAndOrderService,
  ...memberManagement,
  {
    path: '/404',
    key: '/404',
    Layout: BasicLayout,
    login: true,
    hideInMenu: true,
    routes: [{ path: '/404', key: '4040page', Component: NotFound }],
  },
  {
    path: '/',
    key: 'loginRedirect',
    redirect: '/login',
    hideInMenu: true,
    login: false,
  },
  {
    path: '/',
    key: 'unLoginRedirect',
    redirect: '/404',
    hideInMenu: true,
    login: true,
  },
];

function wrapperComponent(routes) {
  routes.forEach((route) => {
    let Component;
    if (route.Layout) {
      Component = withRouter(route.Layout);
    }
    if (route.Component) {
      Component = withInitListener(withRouter(route.Component), route.key);
    }
    route.Component = Component;
    if (route.routes) {
      wrapperComponent(route.routes);
    }
  });
  return routes;
}
export default wrapperComponent(routes);
