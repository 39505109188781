import React from 'react';
import './App.less';
import DocumentTitle from 'react-document-title';
import Router from './Router';
import GlobalState from './components/GlobalState';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import BrowserLogger from 'alife-logger';

const BUILD_ENV = process.env.BUILD_ENV;
if (BUILD_ENV === 'prd') {
  BrowserLogger.singleton({
    pid: 'j8017dm5g5@aaabf147844064b',
    appType: 'web',
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
  });
}

function App() {
  return (
    <DocumentTitle title="管理系统">
      <ConfigProvider locale={zhCN}>
        <GlobalState>
          <Router></Router>
        </GlobalState>
      </ConfigProvider>
    </DocumentTitle>
  );
}
export default App;
