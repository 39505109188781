/**************************************************************
 * 通过配置生成表单
 ***************************************************************/
import React, { Component, Fragment } from 'react';
import {
  Input,
  InputNumber,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  Button,
  Cascader,
  Radio,
  Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import FormItemTypes from './FormItemTypes';
const Option = Select.Option;
const { RangePicker, MonthPicker } = DatePicker;

const PLCAEHOLDER = {
  pleaseSelect: '请选择',
  pleaseInput: '请输入',
};
const { TextArea } = Input;

export default class Index extends Component {
  formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };
  style = {
    marginBottom: 10,
  };
  getFormItemComponent = (record) => {
    let InputCom = null;
    let otherOption = record.otherOption || record.props || {};
    if (typeof otherOption === 'function') {
      otherOption = otherOption();
    }
    let commonProps = {
      ...otherOption,
    };
    let propsData = null;
    const { valueKey = 'value', nameKey = 'label' } = record;

    switch (record.type) {
      case FormItemTypes.typeNumber:
        let inputNumberStyle = { width: '100%' };
        propsData = {
          placeholder: PLCAEHOLDER.pleaseInput + record.label,
          ...commonProps,
          style: otherOption.style || inputNumberStyle,
        };
        InputCom = <InputNumber {...propsData} />;
        break;
      case FormItemTypes.typeSelect:
        propsData = {
          placeholder: PLCAEHOLDER.pleaseSelect + record.label,
          ...commonProps,
        };
        InputCom = (
          <Select {...propsData}>
            {record.options.map((item) => {
              return (
                <Option
                  value={item[valueKey]}
                  key={item[valueKey]}
                  disabled={
                    typeof item['disabled'] === 'function'
                      ? item['disabled']()
                      : item['disabled']
                  }
                >
                  {item[nameKey]}
                </Option>
              );
            })}
          </Select>
        );
        break;
      // 搜索的下拉框
      case FormItemTypes.typeSearchSelect:
        propsData = {
          placeholder: PLCAEHOLDER.pleaseSelect + record.label,
          showSearch: true,
          filterOption: (input, option) =>
            option.props.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0,
          ...commonProps,
        };
        InputCom = (
          <Select {...propsData}>
            {record.options.map((item) => {
              return (
                <Option
                  value={item[valueKey]}
                  key={item[valueKey]}
                  disabled={item['disabled']}
                >
                  {item[nameKey]}
                </Option>
              );
            })}
          </Select>
        );
        break;
      //时间范围选择器
      case FormItemTypes.typeRangePicker:
        propsData = {
          placeholder: PLCAEHOLDER.pleaseSelect + record.label,
          ...commonProps,
          allowClear: otherOption.allowClear || false,
        };
        InputCom = <RangePicker {...propsData} />;
        break;
      //级联选择
      case FormItemTypes.typeCascader:
        propsData = {
          placeholder: PLCAEHOLDER.pleaseSelect + record.label,
          ...commonProps,
        };
        InputCom = <Cascader {...propsData} />;
        break;
      case FormItemTypes.typeDate:
        propsData = {
          placeholder: PLCAEHOLDER.pleaseSelect + record.label,
          ...commonProps,
          allowClear: otherOption.allowClear || false,
        };
        InputCom = <DatePicker style={{ width: '100%' }} {...propsData} />;
        break;
      case FormItemTypes.typeMonth:
        propsData = {
          placeholder: PLCAEHOLDER.pleaseSelect + record.label,
          ...commonProps,
          allowClear: otherOption.allowClear || false,
        };
        InputCom = <MonthPicker style={{ width: '100%' }} {...propsData} />;
        break;

      // 单选框
      case FormItemTypes.typeRadio:
        propsData = {
          ...commonProps,
        };
        InputCom = (
          <Radio.Group {...propsData}>
            {record.options.map((item) => {
              return (
                <Radio value={item[valueKey]} key={item[valueKey]}>
                  {item[nameKey]}
                </Radio>
              );
            })}
          </Radio.Group>
        );
        break;
      // 复选框
      case FormItemTypes.typeCheckbox:
        propsData = {
          ...commonProps,
        };
        InputCom = (
          <Checkbox.Group
            options={record.options}
            {...propsData}
          ></Checkbox.Group>
        );
        break;
      // 搜索和重置按钮
      case FormItemTypes.typeSubmit:
        let extra = record.extra || null;
        let { canSearch = true } = record;
        const { submit, reset } = this.props;
        InputCom = (
          <Fragment>
            {submit ? (
              <Button
                type="primary"
                onClick={submit}
                style={{ marginRight: 20, marginLeft: 16 }}
                disabled={!canSearch}
              >
                确定
              </Button>
            ) : null}
            {reset ? <Button onClick={this.props.reset}>重置</Button> : null}
            {extra}
          </Fragment>
        );
        break;
      case FormItemTypes.typeSpace:
        InputCom = <></>;
        break;
      case FormItemTypes.typeComponent:
        // const Element = React.cloneElement(record.component);
        InputCom = record.component;
        break;
      case FormItemTypes.typeTextArea:
        propsData = {
          placeholder: PLCAEHOLDER.pleaseInput + record.label,
          ...commonProps,
        };
        InputCom = <TextArea {...propsData} />;
        break;
      // 默认普通输入框
      default:
        propsData = {
          placeholder: PLCAEHOLDER.pleaseInput + record.label,
          ...commonProps,
        };
        InputCom = <Input {...propsData} />;
        break;
    }
    return InputCom;
  };
  defaultColNum = 3;

  toMatrix = (list, colNum = this.defaultColNum) => {
    const result = [];
    let index = 0;
    let counter = 0;
    let row = { cols: [] };
    result.push(row);
    while (index < list.length) {
      const cell = list[index];
      if (counter >= colNum) {
        counter = 0;
        row = { cols: [] };
        result.push(row);
      }
      row.cols.push(cell);
      counter += cell.colspan || 1;
      index++;
    }
    return result;
  };
  getUnitSpan = (colNum) => {
    const span = 24;
    colNum = colNum || this.defaultColNum;
    return span / colNum;
  };
  renderCol = (col) => {
    // 如果该列有render方法
    if (col.render) {
      return col.render();
    }
    let colLayOut = col.formItemLayout || {};
    const { getFieldDecorator } = this.props.form;
    if (col.type === FormItemTypes.typeSubmit) {
      // 操作按钮
      return (
        <Form.Item {...colLayOut}>{this.getFormItemComponent(col)}</Form.Item>
      );
    } else if (col.type === FormItemTypes.typeSpace) {
      return <></>;
    } else if (col.type === FormItemTypes.typeText) {
      return (
        <Form.Item label={col.label} {...colLayOut}>
          {col.value}
        </Form.Item>
      );
    } else {
      return (
        <Form.Item label={col.label} {...colLayOut}>
          {getFieldDecorator(col.dataIndex, {
            initialValue: col.initialValue,
            rules: col.rules,
          })(this.getFormItemComponent(col))}
        </Form.Item>
      );
    }
  };

  render() {
    const {
      fieldList,
      formItemLayout = this.formItemLayout,
      style = this.style,
      colNum,
    } = this.props;
    let { formRows } = this.props;
    if (Array.isArray(fieldList, colNum)) {
      formRows = this.toMatrix(fieldList);
    }
    const unitSpan = this.getUnitSpan(colNum);
    return (
      <Form {...formItemLayout} style={style}>
        {formRows.map((formRow, index) => {
          // 如果该行有render方法
          if (formRow.render) {
            return <Fragment key={index}>{formRow.render()}</Fragment>;
          } else {
            return (
              <Fragment key={index}>
                <Row style={formRow.style}>
                  {formRow.cols.map((col, colIndex) => {
                    return (
                      <Col
                        span={(col.colspan || 1) * unitSpan}
                        offset={col.offset || 0}
                        key={colIndex}
                        style={col.colStyle || {}}
                      >
                        {this.renderCol(col)}
                      </Col>
                    );
                  })}
                </Row>
              </Fragment>
            );
          }
        })}
      </Form>
    );
  }
}
Index.FormItemTypes = FormItemTypes;
Index.propTypes = {
  formRows: PropTypes.array, // 表单数据
  fieldList: PropTypes.array,
  colNum: PropTypes.number,
  form: PropTypes.object, // antd 的form对象
  submit: PropTypes.func, // 查询的方法
  reset: PropTypes.func, //  重置的方法
  style: PropTypes.object, // form的样式
  formItemLayout: PropTypes.object, //form的布局
};
