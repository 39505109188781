/*
 * @Author: your name
 * @Date: 2020-11-05 13:46:00
 * @LastEditTime: 2020-11-06 09:51:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ifarm_admin_fe/src/util/send.js
 */
import axios from 'axios';
import { ROOTPATH } from '../config/config';
import emitter from './emitter';
import { message } from 'antd';
export default function send(...value) {
  let token = window.localStorage.getItem('token');
  let config;
  if (typeof value[0] === 'string') {
    config = value[1] || {};
    config.url = value[0];
  } else {
    config = value[0];
  }
  let url = config.url;
  if (url.indexOf('http') !== 0) {
    url = ROOTPATH + url;
  }

  return new Promise((resolve, reject) => {
    axios({
      url,
      method: config.method || 'get',
      headers: Object.assign({
        Authorization: token || '',
      }, config.headers || {}),
      data: config.data,
      withCredentials: (config.headers && config.headers.appid) ? false : true,
      params: config.params,
    }).then((res) => {
      const data = res.data;
      const error = data.error;
      let errorMsg = {
        1005: '权限变更，重新登陆',
        1007: '账号异地登录',
        1008: '登录用户没有匹配公司',
        100100: '登录过期',
        100: '登录状态已过期，请重新登录',
      };
      let msg = '';
      if (errorMsg.hasOwnProperty(error)) {
        msg = errorMsg[error];
        emitter.emit('logout');
        message.error(msg);
        reject(msg);
      } else {
        resolve(res);
      }
    }).catch(msg=>{
      reject(msg);
    })
  });
}
