import send from './send';
import { GATEWAYPATH } from '../config/config';
export default function (params) {
  // const DefaultHeader = {
  //   APPID: 7,
  // };
  const DefaultParams = {
    platform: 0,
    parameter: '',
    utm_content: '',
    appid: 7,
    uid: window.localStorage.getItem('admin_uid'),
    utm_term: document.referrer, 
  };
  send({
    url: `${GATEWAYPATH}/api/v1/utm/event.gif`,
    headers: {
      APPID: 7
    },
    params: Object.assign(DefaultParams, params),
  });
}
