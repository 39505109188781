import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Order = withAsyncComponent(() =>
  import('@/pages/animalHealth/order/Index')
);
const OrderDetail = withAsyncComponent(() =>
  import('@/pages/animalHealth/order/Detail')
);

const AnalyseGoods = withAsyncComponent(() =>
  import('@/pages/animalHealth/order/AnalyseGoods')
);
const AnalyseLiuLiang = withAsyncComponent(() =>
  import('@/pages/animalHealth/order/AnalyseLiuLiang')
);


export default [
  {
    path: '/animal_health',
    name: '集市',
    key: 'animalHealth',
    icon: 'experiment',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/animal_health/order',
        name: '集市订单管理',
        key: 'animalHealthOrder',
        routes: [
          {
            path: '/animal_health/order',
            exact: true,
            name: '订单列表',
            key: 'animalHealthOrderIndex',
            Component: Order,
            hideInMenu: true,
          },
          {
            path: '/animal_health/order/detail/:order_no',
            exact: true,
            name: '订单详情',
            key: 'animalHealthOrderDetail',
            Component: OrderDetail,
            hideInMenu: true,
          },
        ],
      },
      // {
      //   path: '/animal_health/AnalyseLiuLiang',
      //   name: '集市流量来源分析',
      //   key: 'animalHealthAnalyseLiuLiang',
      //   routes: [
      //     {
      //       path: '/animal_health/AnalyseLiuLiang',
      //       exact: true,
      //       name: '订单列表',
      //       key: 'animalHealthAnalyseLiuLiangIndex',
      //       Component: AnalyseLiuLiang,
      //       hideInMenu: true,
      //     }
      //   ],
      // },
      // {
      //   path: '/animal_health/AnalyseGoods',
      //   name: '集市商品分析',
      //   key: 'animalHealthAnalyseGoods',
      //   routes: [
      //     {
      //       path: '/animal_health/AnalyseGoods',
      //       exact: true,
      //       name: '订单列表',
      //       key: 'animalHealthAnalyseGoodsIndex',
      //       Component: AnalyseGoods,
      //       hideInMenu: true,
      //     }
      //   ],
      // },
    ],
  },
];
