import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import 'antd/dist/antd.less';
import './commonStyle/common.less';
import App from './App';
import moment from 'moment'
import 'moment/locale/zh-cn';


//import * as serviceWorker from './serviceWorker';
moment.locale('zh-cn');
ReactDOM.render(<App />, document.getElementById('root'));

//serviceWorker.unregister();
