import React, { Component, Fragment } from 'react';
import CustomerBreadcrumb from '../../../components/CustomerBreadcrumb';
import AliyunOSSUpload from '../../../components/AliyunOSSUpload';
import {
  getVehicleSubmitApi,
  getVehicleDetailApi,
  getDriverListApi,
  getUserListApi,
} from './api';
import { PageRendering } from './components/PageRendering';
import { UcButton } from '../../../components/UcButton';
import { Form, Select, Row, Col, message, Input } from 'antd';
import { CarLayout } from '../util/formLayout';
import { deteleObject } from '../util/filterArr';
import { getDriverUserListApi } from '../driverlogistics/api'
import buryPonit from '../../../util/buryPonit';

const { Option } = Select;
class vehicleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      carHeadList: [], // 车头
      carTailList: [], // 车尾
      carSideList: [], // 车侧
      driverList: [], // 司机列表
      associatedDriverList: [], // 关联司机
      checkDriverList: [], // 已选关联司机
      renderDriverList: [], // 最终渲染的公司
      driverVal: '',
      isAble: false,
    };
  }
  id = this.props.match.params.id;
  componentDidMount() {
    if (this.id) {
      this.getDetail();
    }
    this.getDriverList();
    this.getUserList();
  }
  getUserList = (val) => {
    getDriverUserListApi({
      keyword: val || '',
      status: 9,// 默认审核通过
      page: 1,
      page_size: 10,
    }).then((res) => {
      if (res.data.error === 0) {
        let resData = res.data.data;
        this.setState({
          associatedDriverList: resData.list,
        });
      }
    });
  };
  getDriverList = () => {
    getDriverListApi('').then((res) => {
      this.setState({
        driverList: res,
      });
    });
  };
  getDetail = () => {
    getVehicleDetailApi({
      id: this.id,
    }).then((res) => {
      if (res.data.error === 0) {
        let { data } = res.data;
        this.props.form.setFieldsValue({
          type: data.type + '',
          licensenumber: data.truck_card,
          city: data.truck_city,
          drivertype: data.type + '',
          conductor: data.truck_length,
          maximumpayload: data.truck_load / 1000,
          truck_license: data.truck_license,
          truck_type: data.truck_type,
        });
        let carHeadList = [];
        let carTailList = [];
        let carSideList = [];
        let carHeadObj = {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: data.photo1_url,
        };
        data.photo1_url && carHeadList.push(carHeadObj);
        let carTailObj = {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: data.photo2_url,
        };
        data.photo2_url && carTailList.push(carTailObj);
        let carSideObj = {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: data.photo3_url,
        };
        data.photo3_url && carSideList.push(carSideObj);
        let userList = [];
        data.user_list.map((item) => {
          let obj = {
            user_name: item.user_name + '-' + item.user_mobile,
            uid: item.uid,
          };
          return userList.push(obj);
        });
        this.setState({
          data,
          carHeadList,
          carTailList,
          carSideList,
          renderDriverList: userList || [],
          checkDriverList: userList || [],
        });
      }
    });
  };
  onCarHeadChange = (fileList) => {
    this.setState({
      carHeadList: fileList,
    });
  };
  onCarTailChange = (fileList) => {
    this.setState({
      carTailList: fileList,
    });
  };
  onCarSideChange = (fileList) => {
    this.setState({
      carSideList: fileList,
    });
  };
  onSubmit = (e) => {
    buryPonit({
      utm_souce: 'Logistics_CarDetail_ClickSave',
      utm_campaign: 'module106',
    });
    e && e.preventDefault();
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let { carHeadList, carTailList, carSideList, renderDriverList } =
          this.state;
        let arr = [];
        renderDriverList.map((item) => {
          return arr.push(item.uid);
        });
        getVehicleSubmitApi({
          id: this.id || undefined,
          truck_no: '', // 车辆内部编号
          company_code: '', // 所属公司代码
          truck_card: val.licensenumber, // 车牌号
          name: '', // 车辆名称
          truck_city: val.city, // 车辆所属城市
          truck_length: val.conductor, // 车辆长度，单位米
          truck_load: val.maximumpayload * 1000, // 车辆载重，单位千克
          type: val.drivertype, // 车辆类型
          photo1_url: carHeadList[0].originFileObj
            ? val.car_head[0].url + val.car_head[0].originFileObj.url
            : carHeadList[0].url, // 车头
          photo2_url: carTailList[0].originFileObj
            ? val.car_tail[0].url + val.car_tail[0].originFileObj.url
            : carTailList[0].url, // 车尾
          photo3_url: carSideList[0].originFileObj
            ? val.car_side[0].url + val.car_side[0].originFileObj.url
            : carSideList[0].url,
          uid_list: arr.join(','),
          truck_license: val.truck_license,
          truck_type: val.truck_type,
        }).then((res) => {
          if (res.data.error === 0) {
            message.success('保存成功');
            this.setState({
              isAble: true,
            });
            this.props.history.push('/logistics/vehicle');
          } else {
            message.error(res.data.msg);
          }
        });
      }
    });
  };
  onChangeAssociaterDriver = (val, option) => {
    let obj = {
      user_name: option.props.children,
      uid: option.props.value,
    };
    console.log(option,'---')
    this.state.checkDriverList.push(obj);
    let arr = deteleObject(this.state.checkDriverList);
    this.setState({
      renderDriverList: arr,
    });
  };
  onDeleteAssociaterDriver = (val, ind) => {
    let { renderDriverList, checkDriverList } = this.state;
    renderDriverList.splice(ind, 1);
    checkDriverList.map((item, index) => {
      if (val.uid === item.uid) {
        checkDriverList.splice(index, 1);
      }
      return checkDriverList;
    });
    this.setState(
      {
        renderDriverList,
        checkDriverList,
      },
      () => {
        this.props.form.setFieldsValue({
          associated_driver: '',
        });
      }
    );
  };
  render() {
    let {
      data,
      carHeadList,
      carTailList,
      carSideList,
      isAble,
      associatedDriverList,
      renderDriverList,
    } = this.state;
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Fragment>
        <CustomerBreadcrumb className='breadcrumb'></CustomerBreadcrumb>
        <Form {...CarLayout} style={{ background: '#fff', padding: 10 }}>
          <PageRendering data={data} form={this.props.form} />
          <Form.Item label='车辆照片'>
            <Row>
              <Col span={24}>
                <Form.Item label='车头正面照'>
                  {getFieldDecorator('car_head', {
                    rules: [
                      {
                        required: !carHeadList.length,
                        message: '请上传车头正面照',
                      },
                    ],
                  })(
                    <AliyunOSSUpload
                      fileList={carHeadList}
                      onChange={this.onCarHeadChange}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label='车尾正面照'>
                  {getFieldDecorator('car_tail', {
                    rules: [
                      {
                        required: !carTailList.length,
                        message: '请上传车尾正面照',
                      },
                    ],
                  })(
                    <AliyunOSSUpload
                      fileList={carTailList}
                      onChange={this.onCarTailChange}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label='车辆侧面照'>
                  {getFieldDecorator('car_side', {
                    rules: [
                      {
                        required: !carSideList.length,
                        message: '请上传车辆侧面照',
                      },
                    ],
                  })(
                    <AliyunOSSUpload
                      fileList={carSideList}
                      onChange={this.onCarSideChange}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label='车型'>
            {getFieldDecorator('truck_type')(<Input />)}
          </Form.Item>
          <Form.Item label='车辆行驶证号'>
            {getFieldDecorator('truck_license')(<Input />)}
          </Form.Item>
          <Form.Item label='关联司机'>
            {getFieldDecorator('associated_driver')(
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={this.onChangeAssociaterDriver}
                onSearch={(val) => {
                  this.setState(
                    {
                      associatedDriverList: [],
                    },
                    () => {
                      this.getUserList(val);
                    }
                  );
                }}
              >
                {associatedDriverList.map((item, index) => {
                  return (
                    <Option key={index} value={item.id}>
                      {item.user_name + '(' + item.real_name + ')-' + item.user_mobile}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label='已选关联司机'>
            <div
              className={
                renderDriverList.length
                  ? 'businessLine-container'
                  : 'businessLine-no'
              }
            >
              {renderDriverList.map((item, index) => {
                return (
                  <div key={item.uid} className='selected-businessline'>
                    <span>{item.user_name}</span>
                    <span
                      className='selected-dele'
                      onClick={() => this.onDeleteAssociaterDriver(item, index)}
                    >
                      x
                    </span>
                  </div>
                );
              })}
            </div>
          </Form.Item>
          <Row>
            <Col offset={10}>
              <UcButton
                text={'保存'}
                disabled={isAble}
                clickHandler={this.onSubmit}
              />
            </Col>
          </Row>
        </Form>
      </Fragment>
    );
  }
}

const VehicleDetail = Form.create()(vehicleDetail);
export default VehicleDetail;
