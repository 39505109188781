import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Button } from 'antd';

export class UcButton extends Component {
  render() {
    return (
      <Button
        type={this.props.type}
        disabled={this.props.disabled}
        onClick={this.props.clickHandler}
      >
        {this.props.text}
      </Button>
    );
  }
}

UcButton.defaultProps = {
  text: '按钮',
  type: 'primary',
  disabled: false,
};

UcButton.propTypes = {
  text: propTypes.string,
  mode: propTypes.string,
  disabled: propTypes.bool,
  clickHandler: propTypes.func.isRequired,
};
