import React, { Component } from 'react';
import globalStateContext from '../context/GlobalContext';

export default function withInitListener(WrappedComponent, key) {
  class InitListener extends Component {
    componentDidMount() {
      const { updateActiveRoute } = this.context;
      updateActiveRoute(key);
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  InitListener.contextType = globalStateContext;
  return InitListener;
}
