import send from '../../../util/send';
import { PROMOTEPATH } from '../../../config/config';

// 车辆列表
export function getVehicleListApi(params) {
  return send({ url: PROMOTEPATH + '/admin/truck/index', params });
}

// 车辆保存
export function getVehicleSubmitApi(params) {
  return send({ url: PROMOTEPATH + '/admin/truck/save', params });
}

// 车辆详情
export function getVehicleDetailApi(params) {
  return send({ url: PROMOTEPATH + '/admin/truck/info', params });
}

// 主要、备选司机
export function getDriverListApi(val) {
  return send({
    url: '/api/backend/v1/users',
    params: {
      search: val,
    },
  }).then((res) => {
    if (res.data.error === 0) {
      let { list } = res.data;
      return list || [];
    }
  });
}

// 用户列表
export function getUserListApi(params) {
  return send({ url: '/api/backend/v1/users', params });
}
