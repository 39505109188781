export default {
  posterList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'PosterList',
    pageName: '海报列表页面',
    module: 'Poster',
    moduleName: '海报',
  },
  posterAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'PosterAdd',
    pageName: '海报新增页面',
    module: 'Poster',
    moduleName: '海报',
  },
  posterEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'PosterEdit',
    pageName: '海报编辑页面',
    module: 'Poster',
    moduleName: '海报',
  },
  bannerList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'BannerList',
    pageName: 'banner图列表',
    module: 'Banner',
    moduleName: 'banner图',
  },
  bannerAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'BannerAdd',
    pageName: 'banner图新增',
    module: 'Banner',
    moduleName: 'banner图',
  },
  bannerEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'BannerEdit',
    pageName: 'banner图编辑',
    module: 'Banner',
    moduleName: 'banner图',
  },
  adList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'AdList',
    pageName: '广告位列表',
    module: 'Ad',
    moduleName: '广告位',
  },
  adEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'AdEdit',
    pageName: '广告位编辑',
    module: 'Ad',
    moduleName: '广告位',
  },
  adAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'AdAdd',
    pageName: '广告位新增',
    module: 'Ad',
    moduleName: '广告位',
  },
  videoList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'VideoList',
    pageName: '视频列表',
    module: 'Video',
    moduleName: '视频管理',
  },
  VideoReleased: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'VideoAdd',
    pageName: '视频新增',
    module: 'Video',
    moduleName: '视频管理',
  },
  VideoEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'VideoEdit',
    pageName: '视频编辑',
    module: 'Video',
    moduleName: '视频管理',
  },
  priceList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'PriceList',
    pageName: '价格打开列表页',
    module: 'Price',
    moduleName: '价格管理',
  },
  pricematerialList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'PricematerialList',
    pageName: '订单打开列表页列表',
    module: 'PricematerialList',
    moduleName: '订单打开列表页',
  },
  pricematerialDetail: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'PricematerialDetail',
    pageName: '订单打开列表页编辑',
    module: 'Pricematerial',
    moduleName: '订单打开列表页',
  },
  ordermodify: {
    event: 'Update',
    eventName: '修改',
    page: 'PricematerialUpdate',
    pageName: '订单打开列表页修改',
    module: 'Pricematerial',
    moduleName: '订单打开列表页',
  },
  relatedfarmsList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'RelatedfarmsList',
    pageName: '用户关联农场管理列表',
    module: 'Relatedfarms',
    moduleName: '用户关联农场管理',
  },
  relatedfarmsEdit: {
    event: 'Update',
    eventName: '打开编辑页',
    page: 'RelatedfarmsEdit',
    pageName: '用户关联农场管理编辑',
    module: 'Relatedfarms',
    moduleName: '用户关联农场管理',
  },
  thresholdList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'ThresholdList',
    pageName: '',
    module: 'Threshold',
    moduleName: '农场指标阈值管理',
  },
  thresholdEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'ThresholdEdit',
    pageName: '农场指标阈值管理编辑',
    module: 'Threshold',
    moduleName: '农场指标阈值管理',
  },
  calculationList: {
    event: 'Calculation',
    eventName: '生产指标计算设定',
    page: 'Calculation',
    pageName: '生产指标计算设定页面',
    module: 'Calculation',
    moduleName: '生产指标计算设定',
  },
  administrationList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'AdministrationList',
    pageName: '后台账号管理列表',
    module: 'Administration',
    moduleName: '后台账号管理',
  },
  administrationAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'AdministrationAdd',
    pageName: '后台账号管理新增',
    module: 'Administration',
    moduleName: '后台账号管理',
  },
  administrationEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'AdministrationEdit',
    pageName: '后台账号管理编辑',
    module: 'Administration',
    moduleName: '后台账号管理',
  },
  userList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'UserList',
    pageName: '用户管理列表',
    module: 'User',
    moduleName: '用户管理',
  },
  useredAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'UserAdd',
    pageName: '用户管理新增',
    module: 'User',
    moduleName: '用户管理',
  },
  useredEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'UserEdit',
    pageName: '用户管理编辑',
    module: 'User',
    moduleName: '用户管理',
  },
  organizationList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'OrganizationList',
    pageName: '组织结构管理列表',
    module: 'Organization',
    moduleName: '组织结构管理',
  },
  organizationAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'OrganizationAdd',
    pageName: '组织结构管理新增',
    module: 'Organization',
    moduleName: '组织结构管理',
  },
  organizationEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'OrganizationEdit',
    pageName: '组织结构管理编辑',
    module: 'Organization',
    moduleName: '组织结构管理',
  },
  departmentList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'DepartmentList',
    pageName: '部门管理列表',
    module: 'Department',
    moduleName: '部门管理',
  },
  departmentAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'DepartmentAdd',
    pageName: '部门管理新增',
    module: 'Department',
    moduleName: '部门管理',
  },
  departmentEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'DepartmentEdit',
    pageName: '部门管理编辑',
    module: 'Department',
    moduleName: '部门管理',
  },
  employeeList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'EmployeeList',
    pageName: '员工列表',
    module: 'Employee',
    moduleName: '员工管理',
  },
  employeeAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'EmployeeAdd',
    pageName: '员工新增',
    module: 'Employee',
    moduleName: '员工管理',
  },
  employeeEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'EmployeeEdit',
    pageName: '员工编辑',
    module: 'Employee',
    moduleName: '员工管理',
  },
  feeduncooperatecustomerlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'FeedUncooperateCustomerList',
    pageName: '非合作客户详情',
    module: 'FeedUncooperateCustomer',
    moduleName: '非合作客户详情',
  },
  visitRecordList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'VisitRecordList',
    pageName: '拜访记录打开列表页',
    module: 'VisitRecord',
    moduleName: '拜访记录',
  },
  targetRecordList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'TargetRecordList',
    pageName: '目标记录打开列表页',
    module: 'TargetRecord',
    moduleName: '目标记录',
  },
  feedcustomerList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'FeedcustomerList',
    pageName: '饲料客户管理列表',
    module: 'Feedcustomer',
    moduleName: '饲料客户管理',
  },
  feedcustomerEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'FeedcustomerEdit',
    pageName: '饲料客户管理编辑',
    module: 'Feedcustomer',
    moduleName: '饲料客户管理',
  },
  feedCustomerCompanyEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'FeedCustomerCompanyEdit',
    pageName: '饲料客户公司编辑',
    module: 'Feedcustomer',
    moduleName: '饲料客户管理',
  },
  ExportClueDataDetail: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'ExportClueData',
    pageName: '线索池管理',
    module: 'ExportClueData',
    moduleName: '线索池管理',
  },
  ExportCrmData: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'ExportCrmData',
    pageName: 'CRM统计报表',
    module: 'ExportCrmData',
    moduleName: 'CRM统计报表',
  },
  salesmanList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'SalesmanList',
    pageName: '销售员管理列表',
    module: 'Salesman',
    moduleName: '销售员管理',
  },
  salesmanEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'SalesmanEdit',
    pageName: '销售员管理编辑',
    module: 'Salesman',
    moduleName: '销售员管理',
  },
  feedList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'FeedList',
    pageName: '种植与饲料厂关系设定列表',
    module: 'Feed',
    moduleName: '种植与饲料厂关系设定',
  },
  feedAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'FeedAdd',
    pageName: '种植与饲料厂关系设定新增',
    module: 'Feed',
    moduleName: '种植与饲料厂关系设定',
  },
  feedEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'FeedEdit',
    pageName: '种植与饲料厂关系设定编辑',
    module: 'Feed',
    moduleName: '种植与饲料厂关系设定',
  },
  companyList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'CompanyList',
    pageName: '猪交易公司管理列表',
    module: 'Company',
    moduleName: '猪交易公司管理',
  },
  companyEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'CompanyEdit',
    pageName: '猪交易公司管理编辑',
    module: 'Company',
    moduleName: '猪交易公司管理',
  },
  companyAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'CompanyAdd',
    pageName: '猪交易公司管理新增',
    module: 'Company',
    moduleName: '猪交易公司管理',
  },
  pigTradingList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'PigTradeList',
    pageName: '猪交易用户管理列表',
    module: 'PigTrade',
    moduleName: '猪交易用户管理',
  },
  pigTradingAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'PigTradingAdd',
    pageName: '猪交易用户管理新增',
    module: 'PigTrade',
    moduleName: '猪交易用户管理',
  },
  pigTradingEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'PigTradingEdit',
    pageName: '猪交易用户管理编辑',
    module: 'PigTrade',
    moduleName: '猪交易用户管理',
  },
  areasList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'AreaList',
    pageName: '用户绑定地区列表',
    module: 'Area',
    moduleName: '用户绑定地区',
  },
  areasEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'AreaEdit',
    pageName: '用户绑定地区编辑',
    module: 'Area',
    moduleName: '用户绑定地区',
  },
  maintenanceList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'MaintenanceList',
    pageName: '农场维护列表',
    module: 'Maintenance',
    moduleName: '农场维护',
  },
  roleList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'RoleList',
    pageName: '角色管理列表',
    module: 'Role',
    moduleName: '角色管理',
  },
  roleAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'RoleAdd',
    pageName: '角色管理新增',
    module: 'Role',
    moduleName: '角色管理',
  },
  rolEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'RoleEdit',
    pageName: '角色管理编辑',
    module: 'Role',
    moduleName: '角色管理',
  },
  feedcompanylist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'FeedCompanyList',
    pageName: '饲料公司管理列表',
    module: 'FeedCompany',
    moduleName: '饲料公司管理',
  },
  feedcompanyAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'FeedCompanyAdd',
    pageName: '饲料公司管理新增',
    module: 'FeedCompany',
    moduleName: '饲料公司管理',
  },
  feedcompanyEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'FeedCompanyEdit',
    pageName: '饲料公司管理编辑',
    module: 'FeedCompany',
    moduleName: '饲料公司管理',
  },
  feedcustomercontractList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'FeedCustomerContractList',
    pageName: '饲料客户合同管理列表',
    module: 'FeedCustomerContract',
    moduleName: '饲料客户合同管理',
  },
  contractedit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'FeedCustomerContractEdit',
    pageName: '饲料客户合同管理编辑',
    module: 'FeedCustomerContract',
    moduleName: '饲料客户合同管理',
  },
  authorization: {
    event: 'Auth',
    eventName: '授权页面',
    page: 'Authorization',
    pageName: '授权管理',
    module: 'FeedCustomerContract',
    moduleName: '饲料客户合同管理',
  },
  paylist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'PayList',
    pageName: '支付配置列表',
    module: 'Pay',
    moduleName: '支付配置',
  },
  payconfig: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'PayEdit',
    pageName: '支付配置编辑',
    module: 'Pay',
    moduleName: '支付配置',
  },
  artisanList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'ArtisanList',
    pageName: '技术人员列表',
    module: 'Artisan',
    moduleName: '技术人员',
  },
  artisanEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'ArtisanEdit',
    pageName: '技术人员编辑',
    module: 'Artisan',
    moduleName: '技术人员',
  },
  artisanAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'ArtisanAdd',
    pageName: '技术人员新增',
    module: 'Artisan',
    moduleName: '技术人员',
  },
  serviceList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'ServiceList',
    pageName: '服务列表',
    module: 'Service',
    moduleName: '服务列表',
  },
  premixorderlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'PremixorderList',
    pageName: '预混料订单管理列表',
    module: 'Premixorder',
    moduleName: '预混料订单管理',
  },
  premixorderdetail: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'PremixorderEdit',
    pageName: '预混料订单管理编辑',
    module: 'Premixorder',
    moduleName: '预混料订单管理',
  },
  premixcustomerlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'PremixCustomerList',
    pageName: '预混料客户管理列表',
    module: 'PremixCustomer',
    moduleName: '预混料客户管理',
  },
  premixcustomeredit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'PremixCustomerEdit',
    pageName: '预混料客户管理编辑',
    module: 'PremixCustomer',
    moduleName: '预混料客户管理',
  },
  registeruserlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'RegisterUserList',
    pageName: '注册用户列表',
    module: 'RegisterUser',
    moduleName: '注册用户',
  },
  goodslist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'GoodsList',
    pageName: '商品管理',
    module: 'Goods',
    moduleName: '商品管理',
  },
  goodsAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'GoodsAdd',
    pageName: '商品管理新增',
    module: 'Goods',
    moduleName: '商品管理',
  },
  goodsedit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'GoodsEdit',
    pageName: '商品管理编辑',
    module: 'Goods',
    moduleName: '商品管理',
  },
  billist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'BillList',
    pageName: '账单管理列表',
    module: 'Bill',
    moduleName: '账单管理',
  },
  billdetail: {
    event: 'OpenDetail',
    eventName: '查看详情',
    page: 'BillDetail',
    pageName: '账单管理详情',
    module: 'Bill',
    moduleName: '账单管理',
  },
  vetlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'VetList',
    pageName: '兽医管理列表',
    module: 'Vet',
    moduleName: '兽医管理',
  },
  vetAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'VetAdd',
    pageName: '兽医管理新增',
    module: 'Vet',
    moduleName: '兽医管理',
  },
  vetEdit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'VetEdit',
    pageName: '兽医管理编辑',
    module: 'Vet',
    moduleName: '兽医管理',
  },
  vetDetail: {
    event: 'OpenDetail',
    eventName: '详情',
    page: 'VetDetail',
    pageName: '兽医管理详情',
    module: 'Vet',
    moduleName: '兽医管理',
  },
  vetReportList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'VetReportList',
    pageName: '兽医上线下线时间统计列表',
    module: 'VetReport',
    moduleName: '兽医上线下线时间统计',
  },
  consultationlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'Consultationlist',
    pageName: '问诊管理列表',
    module: 'Consultation',
    moduleName: '问诊管理',
  },
  evaluatelist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'EvaluateList',
    pageName: '评价管理列表',
    module: 'Evaluate',
    moduleName: '评价管理',
  },
  expertrankinglist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'ExpertrankingList',
    pageName: '专家排行列表',
    module: 'Expertranking',
    moduleName: '专家排行',
  },
  feedbacklist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'FeedbackList',
    pageName: '意见反馈列表',
    module: 'Feedback',
    moduleName: '意见反馈',
  },
  messagepushlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'MessagepushList',
    pageName: '通知推送列表',
    module: 'MessagePush',
    moduleName: '通知推送',
  },
  messagepushedit: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'MessagePushEdit',
    pageName: '通知推送',
    module: 'MessagePush',
    moduleName: '通知推送',
  },
  messagepushAdd: {
    event: 'OpenAdd',
    eventName: '打开新增页',
    page: 'MessagePushAdd',
    pageName: '通知推送新增',
    module: 'MessagePush',
    moduleName: '通知推送',
  },
  semenOrderListPage: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'SemenOrderList',
    pageName: '精液订单管理列表',
    module: 'SemenOrder',
    moduleName: '精液订单管理',
  },
  semenOrderDetailPage: {
    event: 'OpenDetail',
    eventName: '详情',
    page: 'SemenOrderDetail',
    pageName: '精液订单管理详情',
    module: 'SemenOrder',
    moduleName: '精液订单管理',
  },
  medicineListPage: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'MedicineList',
    pageName: '兽药商品管理列表',
    module: 'Medicine',
    moduleName: '兽药商品管理',
  },
  editMedicine: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'MedicineEdit',
    pageName: '兽药商品管理编辑',
    module: 'Medicine',
    moduleName: '兽药商品管理',
  },
  medicineCustomersPage: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'MedicineCustomerList',
    pageName: '兽药客户管理列表',
    module: 'MedicineCustomer',
    moduleName: '兽药客户管理',
  },
  medicineCustomersEditPage: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'MedicineCustomerList',
    pageName: '兽药客户管理列表',
    module: 'MedicineCustomer',
    moduleName: '兽药客户管理',
  },
  medicineCustomerClassPage: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'MedicineCustomerClassList',
    pageName: '兽药客户分类管理列表',
    module: 'MedicineCustomerClass',
    moduleName: '兽药客户分类管理',
  },
  medicineOrderListPage: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'MedicineOrderList',
    pageName: '兽药订单管理列表',
    module: 'MedicineOrder',
    moduleName: '兽药订单管理',
  },
  medicineOrderDetailPage: {
    event: 'OpenEdit',
    eventName: '打开编辑页',
    page: 'MedicineOrderDetail',
    pageName: '兽药订单管理详情',
    module: 'medicineOrder',
    moduleName: '兽药订单管理',
  },
  biddingreportList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'BiddingReportList',
    pageName: '竞价过程报表列表',
    module: 'BiddingReport',
    moduleName: '竞价过程报表',
  },
  statisticallist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'SatisticalList',
    pageName: '贷款-每日统计列表',
    module: 'Satistical',
    moduleName: '贷款-每日统计',
  },
  monthlystatisticslist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'MonthlyStatisticsList',
    pageName: '贷款-月度统计列表',
    module: 'MonthlyStatistics',
    moduleName: '贷款-月度统计',
  },
  loanstatussummarylist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'LoanStatusSummaryList',
    pageName: '贷款-状态汇总列表',
    module: 'LoanStatusSummary',
    moduleName: '贷款-状态汇总',
  },
  loanorderlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'LoanOrderList',
    pageName: '贷款-申请明细列表',
    module: 'LoanOrder',
    moduleName: '贷款-申请明细',
  },
  loansorderdetail: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'LoanOrderDetailList',
    pageName: '贷款-申请明细详情',
    module: 'LoanOrderDetail',
    moduleName: '贷款-申请明细详情',
  },
  loanorderdetails: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'LoanOrderReleaseDetailList',
    pageName: '贷款-放款明细列表',
    module: 'LoanOrderReleaseDetail',
    moduleName: '贷款-放款明细',
  },
  repaymentlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'RepaymentList',
    pageName: '贷款-还款明细列表',
    module: 'Repayment',
    moduleName: '贷款-还款明细',
  },
  repaymentDetail: {
    event: 'OpenDetail',
    eventName: '详情',
    page: 'RepaymentDetail',
    pageName: '贷款-还款明细详情',
    module: 'Repayment',
    moduleName: '贷款-还款明细',
  },
  customerslist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'CustomerStatistics',
    pageName: '贷款-客户统计列表',
    module: 'CustomerStatistics',
    moduleName: '贷款-客户统计',
  },
  loansummarydetailList: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'LoanSummaryDetailList',
    pageName: '贷款-汇总报表列表',
    module: 'LoanSummaryDetail',
    moduleName: '贷款-汇总报表',
  },
  loansummarystatisticlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'LoanSummaryStatisticList',
    pageName: '贷款-汇总统计列表',
    module: 'LoanSummaryStatistic',
    moduleName: '贷款-汇总统计',
  },
  userauditlist: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'UserAuditList',
    pageName: '用户审计列表',
    module: 'UserAudit',
    moduleName: '用户审计',
  },
  digitalSignageAll: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'DigitalSignageAllList',
    pageName: '数字化看板-全部',
    module: 'DigitalSignage',
    moduleName: '数字化看板',
  },
  digitalSignageCustomer: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'digitalSignageCustomerList',
    pageName: '数字化看板-客户维度',
    module: 'DigitalSignage',
    moduleName: '数字化看板',
  },
  digitalSignageProduct: {
    event: 'OpenList',
    eventName: '打开列表页',
    page: 'digitalSignageProductList',
    pageName: '数字化看板-产品维度',
    module: 'DigitalSignage',
    moduleName: '数字化看板',
  },
};
