import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const StatisticalList = withAsyncComponent(() =>
  import('@/pages/finance/financialStatistics/List')
); // 湖南农行数据统计
const LoanorderList = withAsyncComponent(() =>
  import('@/pages/finance/loanorder/List')
); // 湖南农行订单明细
const LoanorderDetail = withAsyncComponent(() =>
  import('@/pages/finance/loanorder/Detail')
); // 湖南农行订单明细详情

const LoanstatussummaryList = withAsyncComponent(() =>
  import('@/pages/finance/loanstatussummary')
); // 贷款状况汇总列表
const MonthlyStatisticsList = withAsyncComponent(() =>
  import('@/pages/finance/monthlyStatistics/List')
); // 贷款-月度统计
const loanOrderDetailList = withAsyncComponent(() =>
  import('@/pages/finance/loanOrderDetail')
); // 放款订单明细列表
const loanOrderDetails = withAsyncComponent(() =>
  import('@/pages/finance/loanOrderDetail/detail')
); // 放款订单明细详情
const RepaymentlList = withAsyncComponent(() =>
  import('@/pages/finance/repayment')
); // 还款订单明细列表
const RepaymentlDetail = withAsyncComponent(() =>
  import('@/pages/finance/repayment/detail')
); // 还款订单明细详情
const CustomerstatisticsList = withAsyncComponent(() =>
  import('@/pages/finance/customerStatistics/index.tsx')
); // 贷款-客户统计

const LoanSummaryDetail = withAsyncComponent(() =>
  import('@/pages/finance/loanSummaryDetail/List')
); // 贷款-汇总报表

const LoanSummaryStatistic = withAsyncComponent(() =>
  import('@/pages/finance/loanSummaryStatistic/List')
); //贷款-汇总统计

const MessagePush = withAsyncComponent(() =>
  import('@/pages/finance/loanOrderDetail/MessagePush')
); //贷款-逾期提醒配置

const Deposit = withAsyncComponent(() =>
  import('@/pages/finance/deposit/Index')
); //贷款-客户保证金管理

const DepositDetail = withAsyncComponent(() =>
  import('@/pages/finance/deposit/Detail')
); //贷款-客户保证金详情

const DepositOffset = withAsyncComponent(() =>
  import('@/pages/finance/depositOffset/Index')
); //贷款-保证金充抵

const DepositOffsetDetail = withAsyncComponent(() =>
  import('@/pages/finance/depositOffset/Detail')
); //贷款-保证金充抵

const Approve = withAsyncComponent(() =>
  import('@/pages/finance/approve/Index')
); //贷款-贷后信息

const LoanForewarning = withAsyncComponent(() =>
  import('@/pages/finance/loanForewarning/Info')
); //贷款-预警信息

const LoanApproval = withAsyncComponent(() =>
  import('@/pages/finance/loanApproval/Index')
); //贷款-申请审批

const LoanApprovalDetail = withAsyncComponent(() =>
  import('@/pages/finance/loanApproval/Detail')
); //贷款-申请审批

const LoanLaterStageDetail = withAsyncComponent(() =>
  import('@/pages/finance/loanLaterStage/Detail')
); //贷款-贷后信息

const LoanLaterStageList = withAsyncComponent(() =>
  import('@/pages/finance/loanLaterStage/List')
); //贷款-贷后列表

const financialRoute = [
  {
    path: '/finance/loanapproval',
    name: '贷款-申请审批',
    key: 'loanApproval',
    icon: 'audit',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/finance/loanapproval',
        exact: true,
        key: 'loanApprovalContainer',
        Component: LoanApproval,
        hideInMenu: true,
      },
      {
        path: '/finance/loanapproval/detail',
        exact: true,
        key: 'loanApprovalDetailContainer',
        Component: LoanApprovalDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/loanstatistical',
    name: '贷款-每日统计',
    key: 'statistical',
    icon: 'pie-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/loanstatistical',
        exact: true,
        key: 'statisticallist',
        Component: StatisticalList,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/monthlystatistics',
    name: '贷款-月度统计',
    key: 'monthlystatistics',
    icon: 'pie-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/monthlystatistics',
        exact: true,
        key: 'monthlystatisticslist',
        Component: MonthlyStatisticsList,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/loanstatussummary',
    name: '贷款-状态汇总',
    key: 'loanstatussummary',
    icon: 'pie-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/loanstatussummary',
        exact: true,
        key: 'loanstatussummarylist',
        Component: LoanstatussummaryList,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/loanorder',
    name: '贷款-申请明细',
    key: 'loanorder',
    icon: 'container',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/loanorder',
        exact: true,
        key: 'loanorderlist',
        Component: LoanorderList,
        hideInMenu: true,
      },
      {
        path: '/loanorder/detail/:id',
        exact: true,
        name: '贷款-申请明细详情',
        key: 'loansorderdetail',
        Component: LoanorderDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/loanorderdetails',
    name: '贷款-放款明细',
    key: 'loanorderdetails',
    icon: 'money-collect',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/loanorderdetails',
        exact: true,
        key: 'loanorderdetailslist',
        Component: loanOrderDetailList,
        hideInMenu: true,
      },
      {
        path: '/loanorderdetails/detail/:id',
        exact: true,
        name: '贷款-放款明细详情',
        key: 'loanordersdetail',
        Component: loanOrderDetails,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/repayment',
    name: '贷款-还款明细',
    key: 'repayment',
    icon: 'pay-circle',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/repayment',
        exact: true,
        key: 'repaymentlist',
        Component: RepaymentlList,
        hideInMenu: true,
      },
      {
        path: '/repayment/detail/:id',
        exact: true,
        name: '贷款-还款明细详情',
        key: 'repaymentDetail',
        Component: RepaymentlDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/customers',
    name: '贷款-客户统计',
    key: 'customerstatistics',
    icon: 'pie-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/customers',
        exact: true,
        key: 'customerslist',
        Component: CustomerstatisticsList,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/finance/loansummarydetail',
    name: '贷款-汇总报表',
    key: 'loansummarydetail',
    icon: 'bar-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/finance/loansummarydetail',
        exact: true,
        key: 'loansummarydetailList',
        Component: LoanSummaryDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/finance/LoanSummaryStatistic',
    name: '贷款-汇总统计',
    key: 'loansummarystatistic',
    icon: 'bar-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/finance/loansummarystatistic',
        exact: true,
        key: 'loansummarystatisticlist',
        Component: LoanSummaryStatistic,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/loanordermessage',
    name: '贷款-逾期提醒配置',
    key: 'loanOrderMessage',
    icon: 'container',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/loanordermessage',
        exact: true,
        key: 'loanOrderMessageDetail',
        Component: MessagePush,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/finance/deposit',
    name: '贷款-客户保证金管理',
    key: 'financeDeposit',
    icon: 'container',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/finance/deposit',
        exact: true,
        key: 'financeDepositList',
        Component: Deposit,
        hideInMenu: true,
      },
      {
        path: '/finance/deposit/detail/:id',
        exact: true,
        key: 'financeDepositDetail',
        Component: DepositDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/finance/depositoffset',
    name: '贷款-保证金冲抵管理',
    key: 'financeDepositOffset',
    icon: 'container',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/finance/depositoffset',
        exact: true,
        key: 'financeDepositOffsetList',
        Component: DepositOffset,
        hideInMenu: true,
      },
      {
        path: '/finance/depositoffset/detail/:id',
        exact: true,
        key: 'financeDepositOffsetDetail',
        Component: DepositOffsetDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/finance/approve',
    name: '客户审批',
    key: 'financeApprove',
    icon: 'container',
    auth: true,
    login: true,
    Layout: BasicLayout,
    hideInMenu: true,
    routes: [
      {
        path: '/finance/depositoffset/:id',
        exact: true,
        key: 'financeApproveDetail',
        Component: Approve,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/finance/loanforewarning',
    name: '贷款-预警信息',
    key: 'loanForewarning',
    icon: 'warning',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/finance/loanforewarning',
        exact: true,
        key: 'loanForewarningInfo',
        Component: LoanForewarning,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/finance/loanlaterstage',
    name: '贷款-贷后列表',
    key: 'loanLaterStage',
    icon: 'unordered-list',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/finance/loanlaterstage',
        exact: true,
        key: 'LoanLaterStageList',
        Component: LoanLaterStageList,
        hideInMenu: true,
      },
      {
        path: '/finance/loanlaterstage/detail/:id',
        exact: true,
        key: 'LoanLaterStageDetail',
        Component: LoanLaterStageDetail,
        hideInMenu: true,
      },
    ],
  },
];


export { financialRoute };
