import React, { Component } from 'react';
import pageBuryPoint from '../pageBuryPoint/index';

export default function withAsyncComponent(getComponent) {
  return class AsyncComponent extends Component {
    static Component = null;
    state = { Component: AsyncComponent.Component };

    componentDidMount() {
      if (!this.state.Component) {
        getComponent()
          .then(({ default: Component }) => {
            AsyncComponent.Component = Component;
            this.setState({ Component });
          })
          .catch((e) => {
            console.error(e);
          });
      }
      pageBuryPoint(this.props.routeKey);
    }
    render() {
      const { Component } = this.state;
      if (Component) {
        return <Component {...this.props} />;
      }
      return null;
    }
  };
}
