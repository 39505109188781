const FormItemTypes = {
  typeInput: 'input',
  typeTextArea: 'textarea',
  typeNumber: 'number',
  typeSelect: 'select',
  typeSearchSelect: 'searchSelect',
  typeCascader: 'cascader',
  typeRadio: 'radio',
  typeRangePicker: 'rangePicker',
  typeDate: 'date',
  typeMonth: 'month',
  typeCheckbox: 'checkbox',
  typeSpace: 'space',
  typeText: 'text',
  typeSubmit: 'submit',
  typeComponent: 'component'
};
export default FormItemTypes;
