import React, { useState, useEffect } from 'react';
import CustomerBreadcrumb from '@/components/CustomerBreadcrumb';
import CpCard from '@/components/CpCard/Index';
import {
  Table,
  Button,
  Divider,
  Modal,
  Tag,
  message,
  Input,
  Form,
  Row,
  Col,
} from 'antd';
import {
  getSpareArticleListApi,
  getTagListApi,
  deleteTagApi,
  addTagApi,
  publishApi,
  deleteSpareArticleApi,
  getSpareArticleContentApi,
} from './api';
import './Article.less';
import buryPonit from '../../util/buryPonit';

export function SpareArticleList(props) {
  const [list, setList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: '序号',
      align: 'center',
      dataIndex: 'serialNumber',
      width: 80,
      render: (val, record, index) => {
        return (+pageInfo.page - 1) * +pageInfo.pageSize + index + 1;
      },
    },
    {
      title: '时间',
      align: 'center',
      dataIndex: 'updateTime',
    },
    {
      title: '标题',
      align: 'center',
      dataIndex: 'title',
    },
    {
      title: '来源',
      align: 'center',
      dataIndex: 'spiderSourceNote',
    },
    {
      title: '阅读量',
      align: 'center',
      dataIndex: 'readNum',
      width: 100,
    },
    {
      title: '发布时间',
      align: 'center',
      dataIndex: 'mediaCreateTime',
    },
    {
      title: '操作',
      align: 'center',
      render(record) {
        return (
          <>
            <a
              onClick={() => {
                onPublish(record.id);
              }}
            >
              同步文章
            </a>
            <Divider type="vertical" />
            <a
              onClick={() => {
                onDelete(record.id);
              }}
            >
              删除
            </a>
            <Divider type="vertical" />
            <a
              onClick={() => {
                onPreivew(record.id);
              }}
            >
              预览
            </a>
          </>
        );
      },
    },
  ];

  useEffect(getSpareArticleList, [pageInfo]);

  function getSpareArticleList() {
    buryPonit({
      utm_souce: 'SpareArticle_SpareArticle_Query',
      utm_campaign: 'module103',
    });
    getSpareArticleListApi(pageInfo).then(({ list, total }) => {
      setList(list);
      setTotal(total);
    });
  }

  function onPublish(id) {
    publishApi({ id })
      .then(() => {
        message.success('同步成功');
        getSpareArticleList();
      })
      .catch((msg) => {
        message.error(msg);
      });
  }

  function onDelete(id) {
    deleteSpareArticleApi({ id })
      .then(() => {
        message.success('删除成功');
        getSpareArticleList();
      })
      .catch((msg) => {
        message.error(msg);
      });
  }

  const [visibleModal, setVisibleModal] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [saving, setSaving] = useState(false);
  function openModal() {
    setVisibleModal(true);
  }
  function closeModal() {
    setVisibleModal(false);
  }
  function onRemoveTag(val) {
    deleteTagApi({
      id: val,
    })
      .then(() => {
        let index = -1;
        tagList.forEach((item, i) => {
          if (item.value === val) {
            index = i;
          }
        });
        const operList = [...tagList];
        operList.splice(index, 1);
        index !== -1 && setTagList(operList);
      })
      .catch((msg) => {
        message.error(msg);
      });
  }
  function addKeyword() {
    const {
      form: { getFieldValue },
    } = props;
    const keyWord = getFieldValue('keyWord');
    if (saving) {
      return;
    }
    if (trim(keyWord) === '') {
      message.error('请输入关键字');
      return;
    }
    setSaving(true);
    addTagApi({
      word: keyWord,
    })
      .then(({ id, word }) => {
        setTagList([
          ...tagList,
          {
            value: id,
            label: word,
          },
        ]);
      })
      .catch((msg) => {
        message.error(msg);
      })
      .finally(() => {
        setSaving(false);
      });
  }
  function trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, '');
  }
  useEffect(() => {
    if (!visibleModal) {
      return;
    }
    getTagListApi().then(({ list }) => {
      setTagList(
        list.map((item) => {
          return {
            label: item.word,
            value: item.id,
          };
        })
      );
    });
  }, [visibleModal]);

  const [visiblePreivewModel, setVisiblePreivewModel] = useState(false);
  const [content, setContent] = useState('');
  function onPreivew(id) {
    setVisiblePreivewModel(true);
    setContent('');
    getSpareArticleContentApi({ id }).then((content) => {
      console.log(content);
      setContent(content);
    });
  }
  return (
    <div>
      <CustomerBreadcrumb />
      <CpCard>
        <Button type="primary" onClick={openModal}>
          关键词管理
        </Button>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={list}
          pagination={{
            pageSize: +pageInfo.pageSize,
            total: +total,
            current: +pageInfo.page,
            onChange: (page) => {
              setPageInfo(Object.assign({}, pageInfo, { page }));
            },
          }}
        />
      </CpCard>
      <Modal
        title="关键词管理"
        visible={visibleModal}
        onCancel={closeModal}
        footer={<Button onClick={closeModal}>关闭</Button>}
      >
        {tagList.map((item) => {
          return (
            <Tag
              style={{
                marginBottom: 4,
              }}
              key={item.value}
              closable
              visible={true}
              onClose={() => {
                onRemoveTag(item.value);
              }}
            >
              {item.label}
            </Tag>
          );
        })}
        <Row
          style={{
            marginTop: 16,
          }}
        >
          <Col span={12}>
            {props.form.getFieldDecorator('keyWord', {
              initialValue: '',
            })(<Input placeholder="请输入关键字" />)}
          </Col>
          <Col span={8} offset={1}>
            {' '}
            <Button onClick={addKeyword} loading={saving} type="primary">
              添加关键字
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={visiblePreivewModel}
        width={420}
        footer={null}
        onCancel={() => {
          setVisiblePreivewModel(false);
        }}
      >
        <div className="spare-article-phone-box">
          <div
            className="phone_preview_content"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></div>
        </div>
      </Modal>
    </div>
  );
}
export default Form.create()(SpareArticleList);
