/**************************************************************
 * 部门管理页面
 ***************************************************************/
import React, { useState } from 'react';
import { Form, Button, Table, Popconfirm, message } from 'antd';
import CustomerBreadcrumb from '@/components/CustomerBreadcrumb';
import CpCard from '@/components/CpCard/Index';
import CpForm from '@/components/CpForm/Index';
import { getEmpolyeeInfoApi, departEmpolyeeApi } from './api';

const { FormItemTypes } = CpForm;

function Index(props) {
  const fieldList = [
    {
      label: '手机号',
      type: FormItemTypes.typeInput,
      dataIndex: 'telMobile',
    },
    {
      render() {
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              onClick={onSearch}
              style={{ marginRight: 16 }}
            >
              搜索
            </Button>
          </div>
        );
      },
    },
  ];
  const [employeeList, setEmployeeList] = useState([]);
  const columns = [
    {
      title: '手机号',
      align: 'center',
      dataIndex: 'telMobile',
    },
    {
      title: '姓名',
      align: 'center',
      dataIndex: 'username',
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: 'statusName',
    },
    {
      title: '更新时间',
      align: 'center',
      dataIndex: 'updateTime',
    },
    {
      title: '操作',
      align: 'center',
      render: (record) => {
        function confirm() {
          logOff(record.telMobile);
        }
        return (
          <Popconfirm
            title="确定要注销权限吗？"
            onConfirm={confirm}
            okText="是"
            cancelText="否"
          >
            <a href="">权限注销</a>
          </Popconfirm>
        );
      },
    },
  ];
  async function logOff(telMobile) {
    try {
      await departEmpolyeeApi({
        telMobile,
      });
      message.success('操作成功');
      getEmpolyeeInfo(telMobile);
    } catch (msg) {
      message.error('注销失败');
    }
  }
  const [telMobile, setTelMobile] = useState('');
  async function onSearch() {
    const {
      form: { getFieldsValue },
    } = props;
    try {
      await getEmpolyeeInfo(getFieldsValue().telMobile || '');
      setTelMobile(getFieldsValue().telMobile || '');
    } catch (msg) {
      message.error(msg);
    }
  }
  async function getEmpolyeeInfo(telMobile) {
    try {
      const res = await getEmpolyeeInfoApi({
        telMobile,
      });
      setEmployeeList(res);
    } catch (msg) {
      message.error(msg);
    }
  }
  function onReset() {
    const { form } = props;
    form.resetFields();
    setEmployeeList([]);
  }

  return (
    <>
      <CustomerBreadcrumb />
      <CpCard>
        <CpForm form={props.form} fieldList={fieldList}></CpForm>
        <Table
          style={{ width: 900 }}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={employeeList}
          pagination={false}
        />
      </CpCard>
    </>
  );
}
export default Form.create()(Index);
