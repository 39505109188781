import routeMap from './routeMap';
import buryPonit from '../util/buryPonit';
export default function (routeKey) {
  const routeInfo = routeMap[routeKey];
  if (!routeInfo) {
    return;
  }
  buryPonit({
    utm_souce: `${routeInfo.module}_${routeInfo.page}_${routeInfo.event}`,
    utm_campaign: `Module_${routeInfo.module}`,
  });
}
