const BUILD_ENV = process.env.BUILD_ENV;
const config = {
  dev: {
    ROOTPATH: 'https://admin-inner-api-dev.cpchina.cn', // python接口
    PROMOTEPATH: 'https://srv-iorder-dev.cpchina.cn', // php接口
    PATH: 'https://page-h5-dev.cpchina.cn/', // 代码发布地址repayment
    GATEWAYPATH: 'https://public-dev.cpchina.cn', //公共服务接口
    APIPATH: 'https://cp-upstream-gateway-uat.cpchina.cn' // 种植博士后台本地接口
  },
  prd: {
    ROOTPATH: 'https://admin-inner-api.cpchina.cn',
    PROMOTEPATH: 'https://srv-iorder.cpchina.cn',
    PATH: 'https://page.cpchina.cn/',
    GATEWAYPATH: 'https://public.cpchina.cn', //公共服务接口
    APIPATH: 'https://cp-upstream-gateway.cpchina.cn' // 种植博士后台本地接口
  },
};

export const ROOTPATH = config[BUILD_ENV].ROOTPATH;
export const PROMOTEPATH = config[BUILD_ENV].PROMOTEPATH;
export const PATH = config[BUILD_ENV].PATH;
export const GATEWAYPATH = config[BUILD_ENV].GATEWAYPATH;
export const APIPATH = config[BUILD_ENV].APIPATH;

