import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const MarketCustomer = withAsyncComponent(() =>
  import('@/pages/marketCustomer/Index')
);
const MarketCustomerDetail = withAsyncComponent(() =>
  import('@/pages/marketCustomer/Detail')
);
export default [
  {
    path: '/marketcustomer',
    name: '市场客户',
    key: 'marketCustomer',
    icon: 'idcard',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/marketcustomer',
        exact: true,
        key: 'marketCustomerList',
        Component: MarketCustomer,
        hideInMenu: true,
      },
      {
        path: '/marketcustomer/edit/:id',
        exact: true,
        key: 'marketCustomerEdit',
        Component: MarketCustomerDetail,
        hideInMenu: true,
      },
    ],
  },
];
