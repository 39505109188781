import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const PremixOrder = withAsyncComponent(() =>
  import('@/pages/premixorder/List')
); // 预混料订单列表
const PremixOrderDetail = withAsyncComponent(() =>
  import('@/pages/premixorder/Detail')
); // 预混料订单详情
const PremixCustomer = withAsyncComponent(() =>
  import('@/pages/premixcustomer/List')
); // 预混料饲料客户列表
const PremixCustomerDetail = withAsyncComponent(() =>
  import('@/pages/premixcustomer/CustomerEdit')
); // 预混料编辑饲料客户
const RegisterUser = withAsyncComponent(() => import('@/pages/registeruser')); // 注册用户
const GoodsList = withAsyncComponent(() => import('@/pages/goods')); // 商品管理列表
const GoodsDetail = withAsyncComponent(() => import('@/pages/goods/detail')); // 商品管理新增、编辑
const ArtisanList = withAsyncComponent(() => import('@/pages/artisan/List')); // 技术人员列表
const ArtisanAdd = withAsyncComponent(() =>
  import('@/pages/artisan/ArtisanAdd')
); // 技术人员新增
const ServiceList = withAsyncComponent(() => import('@/pages/service')); // 服务列表

const Premix = [
  {
    path: '/artisan',
    name: '技术人员',
    key: 'artisan',
    icon: 'usergroup-add',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/artisan',
        exact: true,
        key: 'artisanList',
        Component: ArtisanList,
        hideInMenu: true,
      },
      {
        path: '/artisan/add',
        name: '编辑技术人员',
        key: 'artisanEdit',
        Component: ArtisanAdd,
        hideInMenu: true,
      },
      {
        path: '/artisan/edit/:id',
        name: '新增技术人员',
        key: 'artisanAdd',
        Component: ArtisanAdd,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/service',
    name: '服务列表',
    key: 'service',
    icon: 'solution',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/service',
        exact: true,
        key: 'serviceList',
        Component: ServiceList,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/premixorder',
    name: '预混料订单管理',
    key: 'premixorder',
    icon: 'file-text',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/premixorder',
        exact: true,
        key: 'premixorderlist',
        Component: PremixOrder,
        hideInMenu: true,
      },
      {
        path: '/premixorder/detail/:id',
        name: '预混料订单详情',
        key: 'premixorderdetail',
        Component: PremixOrderDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/premixcustomer',
    name: '预混料客户管理',
    key: 'premixcustomer',
    icon: 'contacts',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/premixcustomer',
        exact: true,
        key: 'premixcustomerlist',
        Component: PremixCustomer,
        hideInMenu: true,
      },
      {
        path: '/premixcustomer/edit/:id',
        name: '编辑预混料客户',
        key: 'premixcustomeredit',
        Component: PremixCustomerDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/registeruser',
    name: '注册用户',
    key: 'registeruser',
    icon: 'usergroup-add',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/registeruser',
        exact: true,
        key: 'registeruserlist',
        Component: RegisterUser,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/goods',
    name: '商品管理',
    key: 'goods',
    icon: 'schedule',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/goods',
        exact: true,
        key: 'goodslist',
        Component: GoodsList,
        hideInMenu: true,
      },
      {
        path: '/goods/add',
        name: '新增商品管理',
        key: 'goodsadd',
        Component: GoodsDetail,
        hideInMenu: true,
      },
      {
        path: '/goods/edit/:id',
        name: '编辑商品管理',
        key: 'goodsedit',
        Component: GoodsDetail,
        hideInMenu: true,
      },
    ],
  },
];

export { Premix };
