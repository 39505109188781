import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Gave = withAsyncComponent(() =>
  import('@/pages/giftbag/OnPresentList')
); // 当场赠送
const Balance = withAsyncComponent(() =>
  import('@/pages/giftbag/BalanceList')
); // 余额赠送


const BigGave = withAsyncComponent(() =>
  import('@/pages/giftbag/BigOnPresentList')
); // 当场赠送
const BigBalance = withAsyncComponent(() =>
  import('@/pages/giftbag/BigBalanceList')
); // 余额赠送


const Consumption = withAsyncComponent(() =>
  import('@/pages/giftbag/Consumption')
); // 余额赠包消费明细
const GaveBag = [
  {
    path: '/giftbag',
    name: '赠包管理',
    key: 'giftbag',
    icon: 'reconciliation',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      //  此两个菜单是小批量导入 先保留
      // {
      //   path: '/giftbag/gave',
      //   name:'当场赠送',
      //   key:'gave',
      //   Component: Gave,
      // },
      // {
      //   path: '/giftbag/balance',
      //   name:'余额赠送',
      //   key:'balance',
      //   Component: Balance,
      // },
      {
        path: '/giftbag/big/gave',
        name:'当场赠送',
        key:'biggave',
        Component: BigGave,
      },
      {
        path: '/giftbag/big/balance',
        name:'余额赠送',
        key:'bigbalance',
        Component: BigBalance,
      },
      {
        path: '/giftbag/consumption',
        name:'余额赠消明细',
        key:'consumption',
        hideInMenu: true,
        Component: Consumption,
      },
    ],
  },
];

export { GaveBag };
