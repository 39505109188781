import React, { Component } from "react";
import { Spin } from "antd";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import withAsyncComponent from "./util/withAsyncComponent";
import GlobalContext from "./context/GlobalContext";
import route from './config/route';

const companyDetail = withAsyncComponent(() =>
  import("./pages/feedcompany/CompanyEdit")
); // 饲料公司

class CustomerRouter extends Component {
  constructor(props) {
    super(props);
    this.guid = 0;
  }
  componentDidMount() {}
  renderRouteTree = routeTree => {
    return (
      <Switch>
        <Route path="/feedcompanyedit/:id" exact component={companyDetail}></Route>
        {routeTree.map((item,index) => {
          const Component = item.Component || item.Layout;
          if (item.redirect) {
            return (
              <Redirect
                key={item.key || index}
                path={item.path}
                to={item.redirect}
              ></Redirect>
            );
          } else {
            return (
              <Route key={item.key || index} path={item.path} exact={!!item.exact}>
                {Component ? (
                  <Component routeKey={item.key}>
                    {Array.isArray(item.routes) && item.routes.length > 0
                      ? this.renderRouteTree(item.routes)
                      : null}
                  </Component>
                ) : Array.isArray(item.routes) && item.routes.length > 0 ? (
                  this.renderRouteTree(item.routes)
                ) : null}
              </Route>
            );
          }
        })}
      </Switch>
    );
  };
  render() {
    const { loginState, routeTree } = this.context;
    return (
      <React.Fragment>
        {loginState === "1" && (
          <Spin
            style={{
              display: "block",
              marginTop: "120px",
              textAlign: "center"
            }}
            size="large"
          />
        )}
        <BrowserRouter>
          {loginState !== "1" ? this.renderRouteTree(routeTree) : null}
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

CustomerRouter.contextType = GlobalContext;
export default CustomerRouter;
