/** 系统管理-new **/
import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const AdminList = withAsyncComponent(() =>
  import('@/pages/administration/List')
); // 管理员管理列表
const AdminDetail = withAsyncComponent(() =>
  import('@/pages/administration/AdminEdit')
); // 新增/编辑管理员管理
const User = withAsyncComponent(() => import('@/pages/usered/List')); // 用户列表
const UserDetail = withAsyncComponent(() => import('@/pages/usered/UseredAdd')); // 新增/编辑用户
const RoleList = withAsyncComponent(() => import('@/pages/role/RoleList')); // 角色列表
const RoleDetail = withAsyncComponent(() => import('@/pages/role/RoleDetail')); // 角色详情

const Systems = [
  {
    path: '/systems',
    name: '系统管理',
    key: 'systems',
    icon: 'appstore',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/systems/administration',
        name: '后台账号管理',
        key: 'administration',
        routes: [
          {
            path: '/systems/administration',
            exact: true,
            key: 'administrationList',
            Component: AdminList,
            hideInMenu: true,
          },
          {
            path: '/systems/administration/add',
            name: '新增管理员',
            key: 'administrationAdd',
            Component: AdminDetail,
            hideInMenu: true,
          },
          {
            path: '/systems/administration/edit/:id',
            name: '编辑管理员',
            key: 'administrationEdit',
            Component: AdminDetail,
            hideInMenu: true,
          },
        ],
      },
      {
        path: '/systems/usered',
        name: '用户管理',
        key: 'usered',
        routes: [
          {
            path: '/systems/usered',
            exact: true,
            key: 'userList',
            Component: User,
            hideInMenu: true,
          },
          {
            path: '/systems/usered/add',
            name: '新增用户',
            key: 'useredAdd',
            Component: UserDetail,
            hideInMenu: true,
          },
          {
            path: '/systems/usered/edit/:id',
            name: '编辑用户',
            key: 'useredEdit',
            Component: UserDetail,
            hideInMenu: true,
          },
        ],
      },
      {
        path: '/systems/role',
        name: '角色管理',
        key: 'role',
        routes: [
          {
            path: '/systems/role',
            exact: true,
            key: 'roleList',
            Component: RoleList,
            hideInMenu: true,
          },
          {
            path: '/systems/role/add/:id/:add',
            name: '角色新增',
            key: 'roleAdd',
            Component: RoleDetail,
            hideInMenu: true,
          },
          {
            path: '/systems/role/edit/:id/:add',
            name: '角色编辑',
            key: 'rolEdit',
            Component: RoleDetail,
            hideInMenu: true,
          },
        ],
      },
    ],
  },
];

export { Systems };
