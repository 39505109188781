import React, { useState, useEffect } from 'react';
import {searchToParams} from '../../util/convert';
import './index.less';
export default function (props) {
  const [src, setSrc] = useState('');
  useEffect(()=>{
    const params = searchToParams(props.location.search);
    setSrc(params.path || '');
  })
  return (
    <div className='three-party-system-container'>
      <iframe src={src} />
    </div>
  );
}
