import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const SemenOrderList = withAsyncComponent(() =>
  import('@/pages/semen/semenOrder/SemenOrderList')
);
const SemenOrderDetail = withAsyncComponent(() =>
  import('@/pages/semen/semenOrder/SemenOrderDetail')
);
const semenRoutes = [
  {
    path: '/semen/order',
    name: '精液订单管理',
    key: 'semenOrderMenu',
    icon: 'file-text',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/semen/order',
        exact: true,
        key: 'semenOrderListPage',
        Component: SemenOrderList,
        hideInMenu: true,
      },
      {
        path: '/semen/order/detail/:id',
        key: 'semenOrderDetailPage',
        Component: SemenOrderDetail,
        hideInMenu: true,
        name: '精液订单详情',
      },
    ],
  },
];
export { semenRoutes };
