import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const VetReport = withAsyncComponent(() =>
  import('@/pages/vetadministration/vet/Report')
); // 兽医列表
const VetDetail = withAsyncComponent(() =>
  import('@/pages/vetadministration/vet/Vetdetail')
); // 兽医详情、编辑
const Consultation = withAsyncComponent(() =>
  import('@/pages/vetadministration/consultation/List')
); // 问诊管理
const Evaluate = withAsyncComponent(() =>
  import('@/pages/vetadministration/evaluate/List')
); // 评价管理
const ExpertRanking = withAsyncComponent(() =>
  import('@/pages/vetadministration/expertRanking/List')
); // 专家排行
const vetRouter = [
  {
    path: '/vet_report',
    name: '兽医上线下线时间统计',
    key: 'vetReport',
    icon: 'download',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/vet_report',
        exact: true,
        key: 'vetReportList',
        Component: VetReport,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/consultation',
    name: '问诊管理',
    key: 'consultation',
    icon: 'diff',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/consultation',
        exact: true,
        key: 'consultationlist',
        Component: Consultation,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/evaluate',
    name: '评价管理',
    key: 'evaluate',
    icon: 'form',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/evaluate',
        exact: true,
        key: 'evaluatelist',
        Component: Evaluate,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/expertranking',
    name: '专家排行',
    key: 'expertranking',
    icon: 'vertical-align-middle',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/expertranking',
        exact: true,
        key: 'expertrankinglist',
        Component: ExpertRanking,
        hideInMenu: true,
      },
    ],
  },
];

export { vetRouter };
