import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const PosterList = withAsyncComponent(() => import('@/pages/poster/List')); // 预混料订单列表
const PosterDetail = withAsyncComponent(() => import('@/pages/poster/Detail')); // 预混料订单列表
const posterRoute = [
  {
    path: '/poster',
    name: '海报管理',
    key: 'poster',
    icon: 'picture',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/poster',
        exact: true,
        key: 'posterList',
        Component: PosterList,
        hideInMenu: true,
      },
      {
        path: '/poster/add',
        exact: true,
        key: 'posterAdd',
        Component: PosterDetail,
        hideInMenu: true,
      },
      {
        path: '/poster/edit/:id',
        exact: true,
        key: 'posterEdit',
        Component: PosterDetail,
        hideInMenu: true,
      },
    ],
  },
];

export default posterRoute;
