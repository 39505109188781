import { createContext, useContext } from 'react';

const context = createContext({
  userTabs: [],
  excludeKeys: [],
  menuTree: [],
  routeTree: [],
  auth: [],
  updateAuth: () => {},
  activeRouteKey: '',
  userInfo: null,
  loginState: '0', // 0:未登录 1:登录中 2：已登录
  selectedMenuKeys: [], //选中菜单
  openMenusKeys: [], //打开菜单
  updateSelectedMenu: () => {},
  updateOpenMenu: () => {},
  updateMenuList: () => {},
  updateActiveRoute: () => {},
  updateLoginState: () => {},
  updateUserInfo: () => {},
});

export default context;

