import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const AdList = withAsyncComponent(() => import('@/pages/ad/Index'));
const AdDetail = withAsyncComponent(() => import('@/pages/ad/Detail'));
const adRoute = [
  {
    path: '/ad',
    name: '广告位管理',
    key: 'ad',
    icon: 'picture',
    login: true,
    auth: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/ad',
        exact: true,
        key: 'adList',
        Component: AdList,
        hideInMenu: true,
      },
      {
        path: '/ad/edit/:id',
        exact: true,
        key: 'adEdit',
        Component: AdDetail,
        hideInMenu: true,
      },
      {
        path: '/ad/add',
        exact: true,
        key: 'adAdd',
        Component: AdDetail,
        hideInMenu: true,
      },
    ],
  },
];

export default adRoute;
