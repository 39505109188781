import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Company = withAsyncComponent(() => import('@/pages/company/Company'));

const MemberIndex = withAsyncComponent(() =>
    import('@/pages/member/Index')
);
const CreateMember = withAsyncComponent(() =>
    import('@/pages/member/CreateMember')
);
const MemberGrade = withAsyncComponent(() =>
    import('@/pages/member/Grade')
);
const CreateGrade = withAsyncComponent(() =>
    import('@/pages/member/CreateGrade')
);


const memberManagement = [
    {
        path: '/member',
        name: '会员管理',
        key: 'memberList',
        icon: 'crown',
        auth: true,
        login: true,
        Layout: BasicLayout,
        routes: [
            {
                path: '/member',
                name: '会员管理',
                exact: true,
                key: 'memberList',
                Component: MemberIndex,
                // hideInMenu: true,
                icon: ' ',
                // routes: [
                //     {
                //         path: '/member/createMember',
                //         name: '新建会员',
                //         exact: true,
                //         key: 'createMember',
                //         Component: CreateMember,
                //         hideInMenu: true,
                //     },
                // ]
            },
            {
                path: '/member/grade',
                name: '会员等级管理',
                key: 'memberGrade',
                Component: MemberGrade,
                // hideInMenu: true,
                icon: ' ',
            },
            {
                path: '/member/createMember/:id',
                name: '新建会员',
                exact: true,
                key: 'createMember',
                Component: CreateMember,
                hideInMenu: true,
            },
            {
                path: '/member/createGrade/:id',
                name: '新建会员等级',
                exact: true,
                key: 'createGrade',
                Component: CreateGrade,
                hideInMenu: true,
            },
        ],
    },
];

export { memberManagement };