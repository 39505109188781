import send from '@/util/send';
import { toHumpObj, toLineObj } from '@/util/convert';
import { PROMOTEPATH } from '@/config/config';

export function getEmpolyeeInfoApi(data) {
  return new Promise((resolve, reject) => {
    send({
      url: PROMOTEPATH + '/admin/employee/trunover-status',
      params: toLineObj(data),
    })
      .then((res) => {
        const { error, data, msg } = res.data;
        if (error === 0) {
          resolve(toHumpObj(data));
        } else {
          reject(msg);
        }
      })
      .catch((msg) => {
        reject(msg);
      });
  });
}
export async function departEmpolyeeApi(data) {
  const res = await send({
    url: PROMOTEPATH + '/admin/employee/trunover',
    params: toLineObj(data),
  });
  const { error, msg } = res.data;
  if (error === 0) {
    return;
  } else {
    Promise.reject(msg);
  }
}
