import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Switch = withAsyncComponent(() =>
  import('@/pages/crmTrack/switch/Index.js')
);
const Track = withAsyncComponent(() =>
  import('@/pages/crmTrack/track/Index.js')
);
const Detail = withAsyncComponent(() =>
  import('@/pages/crmTrack/detail/Index.js')
);
const Error = withAsyncComponent(() =>
  import('@/pages/crmTrack/error/Index.js')
);
const Mileage = withAsyncComponent(() =>
  import('@/pages/crmTrack/mileage/Index.js')
);
const crmTrackRoute = [
  {
    path: '/crmtrack',
    name: '拜访轨迹',
    key: 'crmTrack',
    icon: 'compass',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        name: '上报时间',
        path: '/crmtrack/switch',
        icon: 'clock-circle',
        exact: true,
        key: 'crmTrackSwitch',
        Component: Switch,
      },
      {
        name: '轨迹地图',
        path: '/crmtrack/track',
        icon: 'environment',
        exact: true,
        key: 'crmTrackMap',
        Component: Track,
      },
      {
        name: '轨迹明细',
        path: '/crmtrack/detail',
        icon: 'file-text',
        exact: true,
        key: 'crmTrackDetail',
        Component: Detail,
      },
      {
        name: '异常总结',
        path: '/crmtrack/error',
        icon: 'info-circle',
        exact: true,
        key: 'crmTrackError',
        Component: Error,
      },
      {
        name: '里程统计',
        path: '/crmtrack/mileage',
        icon: 'dashboard',
        exact: true,
        key: 'crmTrackMileage',
        Component: Mileage,
      },
    ],
  },
];

export default crmTrackRoute;
