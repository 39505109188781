import React from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import GlobalContext from '../context/GlobalContext'

class CustomerBreadcrumb extends React.Component {
  render() {
    const { activeRouteKey } = this.context
    const BreadcrumbItem = Breadcrumb.Item
    const routePathMap = this.getRoutePathMap()
    const currentRouteList = routePathMap[activeRouteKey]
    const {className} = this.props
    return (
      <Breadcrumb className={className}>
        {currentRouteList &&
          currentRouteList.map((item, index, arr) => {
            if (!item.path || index === arr.length - 1 || !item.Component) {
              return <BreadcrumbItem key={index}>{item.name}</BreadcrumbItem>
            } else {
              return (
                <BreadcrumbItem key={index}>
                  <Link to={item.path}>{item.name}</Link>
                </BreadcrumbItem>
              )
            }
          })}
      </Breadcrumb>
    )
  }
  getRoutePathMap = () => {
    if (this.routePathMap) {
      return this.routePathMap
    }
    const routePathMap = {}
    const { routeTree } = this.context
    function generateMap(routes, parentKey) {
      routes.forEach(route => {
        routePathMap[route.key] = []
        if (!parentKey) {
          routePathMap[route.key] = [route]
        } else {
          routePathMap[route.key] = [...routePathMap[parentKey], route]
        }
        if (Array.isArray(route.routes) && route.routes.length > 0) {
          generateMap(route.routes, route.key)
        }
      })
    }
    generateMap(routeTree)
    this.routePathMap = routePathMap
    return this.routePathMap
  }
}
CustomerBreadcrumb.contextType = GlobalContext
export default CustomerBreadcrumb
