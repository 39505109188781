import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Index = withAsyncComponent(() => import('@/pages/digitalSignage/Index'));
const CustomerDimension = withAsyncComponent(() =>
  import('@/pages/digitalSignage/CustomerDimension')
);
const ProductDimension = withAsyncComponent(() =>
  import('@/pages/digitalSignage/ProductDimension')
);
export default [
  {
    path: '/digitalsignage',
    name: '数字化看板',
    key: 'digitalsignage',
    icon: 'line-chart',
    login: true,
    auth: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/digitalsignage/all',
        exact: true,
        key: 'digitalSignageAll',
        Component: Index,
        name: '全部',
        icon: ' ',
      },
      {
        path: '/digitalsignage/customer',
        exact: true,
        key: 'digitalSignageCustomer',
        Component: CustomerDimension,
        name: '客户维度',
        icon: ' ',
      },
      {
        path: '/digitalsignage/product',
        exact: true,
        key: 'digitalSignageProduct',
        Component: ProductDimension,
        name: '产品维度',
        icon: ' ',
      },
    ],
  },
];
