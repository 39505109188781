import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const reportExport = withAsyncComponent(() =>
  import('@/pages/reportExport/Index')
);

export default {
  path: '/report/export',
  name: '猪博士报表导出',
  key: 'reportExport',
  icon: 'download',
  auth: true,
  login: true,
  Layout: BasicLayout,
  routes: [
    {
      path: '/report/export',
      exact: true,
      key: 'reportExportMain',
      Component: reportExport,
      hideInMenu: true,
    },
  ],
};
