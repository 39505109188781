/** 意见反馈 **/
import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Feedback = withAsyncComponent(() =>
  import('@/pages/vetadministration/feedback/List')
);

const FeedbackManage = [
  {
    path: '/feedback',
    name: '意见反馈',
    key: 'feedback',
    icon: 'question-circle',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/feedback',
        name: '意见反馈',
        key: 'feedbacks',
        routes: [
          {
            path: '/feedback',
            exact: true,
            key: 'feedbacklist',
            Component: Feedback,
            hideInMenu: true,
          },
        ],
      },
    ],
  },
];
export { FeedbackManage };
