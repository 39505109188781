/** 内容管理 **/
import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Article = withAsyncComponent(() => import('@/pages/article/List')); // 文章管理
const ArticlEdit = withAsyncComponent(() =>
  import('@/pages/article/ArticleEdit')
); // 文章编辑
const VideoList = withAsyncComponent(() => import('@/pages/video/VideoList')); // 视频列表
const VideoReleased = withAsyncComponent(() =>
  import('@/pages/video/VideoReleased')
); // 视频发布
const Bannerlist = withAsyncComponent(() =>
  import('@/pages/banner/BannerList')
); // banner管理列表
const BannerDetail = withAsyncComponent(() =>
  import('@/pages/banner/BannerDetail')
); // banner新增/编辑

const Content = [
  {
    path: '/content',
    name: '内容管理',
    key: 'content',
    icon: 'container',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/content/article',
        name: '文章管理',
        key: 'article',
        routes: [
          {
            path: '/content/article',
            exact: true,
            key: 'articleList',
            Component: Article,
            hideInMenu: true,
          },
          {
            path: '/content/article/add',
            key: 'ArticlEAdd',
            name: '文章发布',
            Component: ArticlEdit,
            hideInMenu: true,
          },
          {
            path: '/content/article/edit/:id',
            name: '编辑文章',
            key: 'articleEdit',
            Component: ArticlEdit,
            hideInMenu: true,
          },
        ],
      },
      {
        path: '/content/video',
        name: '视频管理',
        key: 'video',
        routes: [
          {
            path: '/content/video',
            exact: true,
            key: 'videoList',
            Component: VideoList,
            hideInMenu: true,
          },
          {
            path: '/content/video/videoReleased',
            name: '视频发布',
            key: 'VideoReleased',
            Component: VideoReleased,
            hideInMenu: true,
          },
          {
            path: '/content/video/edit/:id/:type',
            name: '编辑视频',
            key: 'VideoEdit',
            Component: VideoReleased,
            hideInMenu: true,
          },
        ],
      },
      // {
      //   path: '/content/banner',
      //   name: 'banner图管理',
      //   key: 'banner',
      //   routes: [
      //     {
      //       path: '/content/banner',
      //       exact: true,
      //       key: 'bannerList',
      //       Component: Bannerlist,
      //       hideInMenu: true,
      //     },
      //     {
      //       path: '/content/banner/add',
      //       name: '新增banner',
      //       key: 'bannerAdd',
      //       Component: BannerDetail,
      //       hideInMenu: true,
      //     },
      //     {
      //       path: '/content/banner/edit/:id',
      //       name: '编辑banner',
      //       key: 'bannerEdit',
      //       Component: BannerDetail,
      //       hideInMenu: true,
      //     },
      //   ],
      // },
    ],
  },
];
export { Content };
