import React from 'react';
import { Upload, Icon, message, Button, Modal } from 'antd';
import { PROMOTEPATH } from '../../config/config';
import send from '../../util/send';

class AliyunOSSUpload extends React.Component {
  state = {
    OSSData: {},
    previewVisible: false,
    previewImage: '',
  };

  async componentDidMount() {
    await this.init();
  }

  init = async () => {
    try {
      await this.getOSSPolicy();
    } catch (error) {
      message.error(error);
    }
  };

  getOSSPolicy = () => {
    return send({
      url: PROMOTEPATH + '/admin/aliyun/oss-policy',
      method: 'POST',
    }).then((res) => {
      if (res.data.error === 0) {
        let { data } = res.data;
        let obj = {
          dir: data.prefixPath,
          expire: data.expire,
          host: data.host,
          accessId: data.accessid,
          policy: data.policy,
          signature: data.signature,
        };
        this.setState({
          OSSData: obj,
        });
      }
    });
  };

  onChange = ({ fileList }) => {
    const { onChange } = this.props;
    fileList.map((item) => {
      if (item.xhr && item.xhr.responseURL) {
        this.setState({
          previewImage: item.xhr && item.xhr.responseURL,
        });
        return (item.url = item.xhr && item.xhr.responseURL);
      }
    });
    if (onChange) {
      onChange([...fileList]);
    }
  };

  onRemove = (file) => {
    const { fileList, onChange } = this.props;
    const files = fileList.filter((v) => v.url !== file.url);
    if (onChange) {
      onChange(files);
    }
  };

  transformFile = (file) => {
    const { OSSData } = this.state;
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    file.url = OSSData.dir + filename;
    return file;
  };
  getExtraData = (file) => {
    const { OSSData } = this.state;
    return {
      key: file.url,
      OSSAccessKeyId: OSSData.accessId,
      policy: OSSData.policy,
      Signature: OSSData.signature,
    };
  };

  beforeUpload = async () => {
    const { OSSData } = this.state;
    const expire = OSSData.expire * 1000;

    if (expire < Date.now()) {
      await this.init();
    }
    return true;
  };
  onPreview = (val) => {
    this.setState({
      previewVisible: true,
      previewImage: val.url,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  render() {
    const { fileList } = this.props;
    const props = {
      name: 'file',
      fileList: fileList, // 数据
      listType: 'picture', // 类型
      action: this.state.OSSData.host, // 发送的请求
      accept: '.png,.jpg,.jpeg',
      onChange: this.onChange,
      onRemove: this.onRemove,
      transformFile: this.transformFile,
      data: this.getExtraData,
      beforeUpload: this.beforeUpload,
      onPreview: this.onPreview,
    };
    let { previewVisible, previewImage } = this.state;
    return (
      <>
        <Upload {...props}>
          {fileList.length > 0 ? null : (
            <Button>
              <Icon type="upload" /> 上传
            </Button>
          )}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          bodyStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
          onCancel={this.handleCancel}
        >
          <img
            alt="example"
            style={{ width: 300 }}
            src={previewImage}
          />
          <Button style={{
            marginLeft: 16
          }} onClick={()=>{
            window.open(previewImage);
          }} icon="download">下载</Button>
        </Modal>
      </>
    );
  }
}

export default AliyunOSSUpload;
