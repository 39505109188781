import withAsyncComponent from '@/util/withAsyncComponent';
import VehicleDetail from '../../pages/logistics/vehicle/vehicleDetail';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const PigSuperset = withAsyncComponent(() =>
    import('@/pages/superset/pig/List')
); // 直播看板
const PtSuperset = withAsyncComponent(() =>
    import('@/pages/superset/pt/List')
); // 直播看板
const LiveSuperset = withAsyncComponent(() =>
  import('@/pages/superset/live/List')
); // 直播看板

const CrmSuperset = withAsyncComponent(() =>
    import('@/pages/superset/crm/List')
); // 直播看板

const SySuperset = withAsyncComponent(() =>
    import('@/pages/superset/sy/List')
); // 直播看板
const WlSuperset = withAsyncComponent(() =>
    import('@/pages/superset/wl/List')
); // 直播看板
const JrSuperset = withAsyncComponent(() =>
    import('@/pages/superset/jr/List')
); // 直播看板

const ZfSuperset = withAsyncComponent(() =>
    import('@/pages/superset/zf/List')
); // 直播看板

const ZjySuperset = withAsyncComponent(() =>
    import('@/pages/superset/zjy/List')
); // 直播看板

const ZxSuperset = withAsyncComponent(() =>
    import('@/pages/superset/zx/List')
); // 直播看板

const Superset = [
  {
    path: '/superset',
    name: '数据分析看板',
    key: 'superset',
    icon: 'pie-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/superset/pig',
        name: '集市分析看板',
        exact: true,
        key: 'piglist',
        Component: PigSuperset,
      },
      {
        path: '/superset/pt',
        name: '平台分析看板',
        exact: true,
        key: 'ptlist',
        Component: PtSuperset,
      },
      {
        path: '/superset/live',
        name: '直播分析看板',
        exact: true,
        key: 'livelist',
        Component: LiveSuperset,
      },
      {
        path: '/superset/crm',
        name: 'CRM分析看板',
        exact: true,
        key: 'crmlist',
        Component: CrmSuperset,
      },
      {
        path: '/superset/sy',
        name: '兽医分析看板',
        exact: true,
        key: 'sylist',
        Component: SySuperset,
      },
      {
        path: '/superset/wl',
        name: '物流分析看板',
        exact: true,
        key: 'wllist',
        Component: WlSuperset,
      },

      {
        path: '/superset/jr',
        name: '金融分析看板',
        exact: true,
        key: 'jrlist',
        Component: JrSuperset,
      },

      {
        path: '/superset/zf',
        name: '支付分析看板',
        exact: true,
        key: 'zflist',
        Component: ZfSuperset,
      },

      {
        path: '/superset/zjy',
        name: '猪交易分析看板',
        exact: true,
        key: 'zjylist',
        Component: ZjySuperset,
      },

      {
        path: '/superset/zx',
        name: '资讯分析看板',
        exact: true,
        key: 'zxlist',
        Component: ZxSuperset,
      },

    ],
  },
];

export { Superset };
