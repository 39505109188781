/** 字符串驼峰转成下划线 */
export function toHump(name) {
  return name.replace(/_(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  });
}

/* 驼峰转换下划线*/
export function toLine(name) {
  return name.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function toHumpObj(obj) {
  let result;
  if (Array.isArray(obj)) {
    result = [];
    result.push(
      ...obj.map((item) => {
        return toHumpObj(item);
      })
    );
  } else if (typeof obj === 'object' && obj !== null) {
    result = {};
    Object.keys(obj).forEach((key) => {
      result[toHump(key)] = toHumpObj(obj[key]);
    });
  } else {
    result = obj;
  }
  return result;
}

export function toLineObj(obj) {
  let result;
  if (Array.isArray(obj)) {
    result = [];
    result.push(
      ...obj.map((item) => {
        return toLineObj(item);
      })
    );
  } else if (typeof obj === 'object' && obj !== null) {
    result = {};
    Object.keys(obj).forEach((key) => {
      result[toLine(key)] = toLineObj(obj[key]);
    });
  } else {
    result = obj;
  }
  return result;
}

export function deepClone(source, category) {
  let target = {};
  if (Array.isArray(source)) {
    target = [];
    source.forEach((item) => {
      target.push(deepClone(item, category));
    });
  } else if (typeof source === 'object' && source !== null) {
    Object.keys(source).forEach((key) => {
      target[key] = deepClone(source[key], category);
    });
  } else if (typeof source === 'function') {
    target = source;
  } else {
    target = source;
  }
  return category ? category(target) : target;
}

// export function (){

// }

// 拼接URL地址
export function getUrl(params) {
  let newUrl;
  if (typeof params == 'string') {
    newUrl = params;
  } else if (typeof params == 'object') {
    var arr = [];
    for (var i in params) {
      arr.push(i + '=' + (params[i] || ''));
    }
    newUrl = arr.join('&');
  }
  return newUrl;
}

export function searchToParams(url) {
  if (url.indexOf('?') === 0) {
    url = url.substr(1);
  }
  const searchParams = new URLSearchParams(url);
  const result = {};

  [...searchParams.keys()].forEach((key) => {
    result[key] = searchParams.get(key);
  });
  return result;
}

export function forMat(time) {
  var date = new Date(time);
  let Y = date.getFullYear() + '-';
  let M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-';
  let D =
    (date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  let h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  let m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':';
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

export function YYYYMMDD() {
  let d = new Date();
  let curr_date = d.getDate();
  let curr_month = d.getMonth() + 1;
  let curr_year = d.getFullYear();
  if (curr_month.toString().length < 2) {
    curr_month = '0' + curr_month;
  }
  if (curr_date.toString().length < 2) {
    curr_date = '0' + curr_date;
  }
  return curr_year + '-' + curr_month + '-' + curr_date;
}

export function isEmptyDateRange(val) {
  if (!Array.isArray(val)) {
    return true;
  }
  if (val[0] && val[1]) {
    return false;
  }
  return true;
}
