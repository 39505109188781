import React from 'react';
import GlobalContext from '../context/GlobalContext';
import routeCfg from '../config/route';
import send from '../util/send';
import emitter from '../util/emitter';
import { PROMOTEPATH } from '../config/config';

class GlobalState extends React.Component {
  constructor(props) {
    super(props);
    //需要判断是否存在缓存
    const token = window.localStorage.getItem('token');
    this.state = {
      auth: [],
      userTabs: [],
      excludeKeys: ['login', 'password', 'operpassword', 'helpInfo', 'basicUserInfo'],
      menuTree: [],
      routeTree: !token ? this.getLogoutRouteTree() : [],
      userInfo: null,
      activeRouteKey: '',
      loginState: !!token ? '1' : '0', //0: 未登录 1：登录中 2：登录
      selectedMenuKeys: [], //选中菜单
      openMenusKeys: [], //打开菜单
    };
  }

  componentDidMount() {
    emitter.on('logout', () => {
      this.logout(() => {
        window.localStorage.clear();
        window.sessionStorage.clear();
      });
    });
    if (this.state.loginState === '1') {
      this.initMenu();
    }
  }
  initMenu = async () => {
    const menuList = [];
    try {
      const res = await send({
        url: '/api/backend/v1/menu',
        method: 'GET',
      });
      const menuInfo = res.data.data;
      this.updateAuth(menuInfo);
      menuList.push(
        ...menuInfo.map((menu) => {
          return {
            key: menu.name,
          };
        })
      );
    } catch (err) {
      emitter.emit('logout');
      return;
    }
    try {
      const res = await send({
        url: `${PROMOTEPATH}/admin/user/get-syw-menu`,
        method: 'GET',
      });
      const { data, error } = res.data;
      if (error === 0) {
        menuList.push(
          ...data.map((item) => {
            return {
              type: 'treePartyMenu',
              key: `syw-${item.id}`,
              menuTitle: item.title,
              path: item.path,
            };
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
    return this.login(menuList);
  };
  updateAuth = (val) => {
    this.setState({
      auth: val,
    });
  };
  getRoutePathMap = (routeTree) => {
    const routePathMap = {}
    function generateMap(routes, parentKey) {
      routes.forEach(route => {
        routePathMap[route.key] = []
        if (!parentKey) {
          routePathMap[route.key] = [route]
        } else {
          routePathMap[route.key] = [...routePathMap[parentKey], route]
        }
        if (Array.isArray(route.routes) && route.routes.length > 0) {
          generateMap(route.routes, route.key)
        }
      })
    }
    generateMap(routeTree)
    return routePathMap;
  };
  updateMenuList = (menuList) => {
    this.setState({
      menuList,
    });
  };
  removeTab = (targetKey, callback) => {
    let { activeRouteKey } = this.state;
    let lastIndex;
    this.state.userTabs.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.userTabs.filter(pane => pane.key !== targetKey);
    if (panes.length <= 1) {
      panes[0].closable = false;
    }
    if (panes.length && activeRouteKey === targetKey) {
      if (lastIndex >= 0) {
        activeRouteKey = panes[lastIndex].key;
      } else {
        activeRouteKey = panes[0].key;
      }
    }
    this.setState({ userTabs: panes, activeRouteKey }, () => {
      callback(panes, activeRouteKey);
    });
    this.updateSelectedMenu(activeRouteKey);
  };
  updateActiveRoute = (key) => {
    if (this.state.loginState === '0') {
      return;
    }
    const routeTree = this.state.routeTree;
    const pathname = window.location.pathname;
    const search = window.location.search;
    const path = search ? pathname + search : pathname;
    let routeToMenuMap = {};
    if(!this.state.userTabs.some(o => o.key === key) && !this.state.excludeKeys.includes(key)) {
      const routes = this.getRoutePathMap(routeTree)[key];
      const route = routes.filter((item) => {
        return !item.Component;
      });
      const routeObj = {
        content: '',
        closable: false,
      };
      route[0].routes.forEach(item => {
        if (item.key === key) {
          if (item.name) {
            routeObj.key = key;
            routeObj.name = item.name;
            routeObj.path = item.path.includes(':') ? pathname : path;
          } else {
            routeObj.key = item.key;
            routeObj.name = route[0].name;
            routeObj.path = route[0].path.includes(':') ? pathname : path;
          }
        }
      });
      this.state.userTabs.push(routeObj);
      this.state.userTabs.forEach(item => {
        item.closable = this.state.userTabs.length > 1 ? true : false;
      });
      this.setState({
        userTabs: this.state.userTabs
      });
    }
    function getRouteToMenu(routes, parentKey) {
      routes.forEach((route) => {
        if (!parentKey) {
          if (!route.hideInMenu) {
            routeToMenuMap[route.key] = [route.key];
          } else {
            routeToMenuMap[route.key] = [];
          }
        } else {
          if (!route.hideInMenu) {
            routeToMenuMap[route.key] = [
              ...routeToMenuMap[parentKey],
              route.key,
            ];
          } else {
            routeToMenuMap[route.key] = [...routeToMenuMap[parentKey]];
          }
        }
        if (Array.isArray(route.routes) && route.routes.length > 0) {
          getRouteToMenu(route.routes, route.key);
        }
      });
    }
    if (!this.routeToMenuMap) {
      getRouteToMenu(routeTree);
      this.routeToMenuMap = routeToMenuMap;
    } else {
      routeToMenuMap = this.routeToMenuMap;
    }
    const menuKeys = routeToMenuMap[key] || [];
    const selectedMenuKeys = [];
    const openMenusKeys = [...this.state.openMenusKeys];
    menuKeys.forEach((key, index, arr) => {
      if (arr.length - 1 === index) {
        selectedMenuKeys.push(key);
      } else {
        if (openMenusKeys.indexOf(key) === -1) {
          openMenusKeys.push(key);
        }
      }
    });
    this.setState({
      selectedMenuKeys,
      openMenusKeys,
      activeRouteKey: key,
    });
  };
  updateSelectedMenu = (keys, callback) => {
    this.setState(
      {
        selectedMenuKeys: keys,
      },
      callback
    );
  };
  updateOpenMenu = (keys, callback) => {
    this.setState(
      {
        openMenusKeys: keys,
      },
      callback
    );
  };
  login = (menuList, userInfo, callback) => {
    if (typeof userInfo === 'function') {
      callback = userInfo;
      userInfo = null;
    }
    const menuMap = {};
    menuList.forEach((item) => {
      menuMap[item.key] = item;
    });
    const routeTree = [];
    const menuTree = [];
    function parseMenuList(list, menuList, routeList) {
      list.forEach((item) => {
        if (item.login === false) {
          return;
        }
        if (item.auth && !item.authType && !menuMap[item.key]) {
          return;
        }
        if (
          item.authType === 'chilren' &&
          !item.routes.find((subItem) => {
            return subItem.auth && !menuMap[subItem.key];
          })
        ) {
          return;
        }
        let menu = {
          key: item.key,
          path: item.path,
          name: item.name,
          icon: item.icon,
        };
        let route;
        if (item.redirect) {
          route = {
            key: item.key,
            path: item.path,
            name: item.name,
            redirect: item.redirect,
          };
        } else {
          route = {
            key: item.key,
            path: item.path,
            icon: item.icon,
            Layout: item.Layout,
            exact: item.exact,
            Component: item.Component,
            name: item.name,
            hideInMenu: item.hideInMenu,
          };
        }

        if (!item.hideInMenu && menuList) {
          menuList.push(menu);
        }
        routeList.push(route);
        if (Array.isArray(item.routes) && item.routes) {
          let menus = [];
          menu.children = menus;
          let routes = [];
          route.routes = routes;
          parseMenuList(item.routes, menus, routes);
        }
      });
    }
    parseMenuList(routeCfg, menuTree, routeTree);
    function parseThreePartySystem(menuList, menuTree) {
      menuTree.push(
        ...menuList
          .filter((item) => item.type === 'treePartyMenu')
          .map((item) => {
            return {
              key: item.key,
              path: `/three/party/system?path=${encodeURIComponent(item.path)}`,
              name: item.menuTitle,
              icon: 'file-text',
            };
          })
      );
    }
    parseThreePartySystem(menuList, menuTree);
    this.setState(
      {
        menuTree,
        routeTree,
        loginState: '2',
        userInfo,
      },
      callback
    );
    return menuTree;
  };
  getLogoutRouteTree() {
    const routeTree = [];
    function getRouteTree(list, routeList) {
      list.forEach((item) => {
        if (item.auth || item.login === true) {
          return;
        }
        let route;
        if (item.redirect) {
          route = {
            path: item.path,
            redirect: item.redirect,
            name: item.name,
            icon: item.icon,
          };
        } else {
          route = {
            name: item.name,
            path: item.path,
            icon: item.icon,
            Layout: item.Layout,
            exact: item.exact,
            Component: item.Component,
          };
        }
        routeList.push(route);
        if (Array.isArray(item.routes) && item.routes) {
          let routes = [];
          route.routes = routes;
          getRouteTree(item.routes, routeList);
        }
      });
    }
    getRouteTree(routeCfg, routeTree);
    return routeTree;
  }
  logout = (callback) => {
    this.setState(
      {
        menuTree: [],
        userTabs: [],
        routeTree: this.getLogoutRouteTree(),
        loginState: '0',
        userInfo: null,
      },
      callback
    );
  };
  logining = () => {
    this.setState({
      loginState: '1',
    });
  };
  render() {
    return (
      <React.Fragment>
        <GlobalContext.Provider
          value={{
            userTabs: this.state.userTabs,
            excludeKeys: this.state.excludeKeys,
            menuTree: this.state.menuTree,
            routeTree: this.state.routeTree,
            userInfo: this.state.userInfo,
            loginState: this.state.loginState,
            openMenusKeys: this.state.openMenusKeys,
            selectedMenuKeys: this.state.selectedMenuKeys,
            activeRouteKey: this.state.activeRouteKey,
            removeTab: this.removeTab,
            login: this.login,
            logout: this.logout,
            logining: this.logining,
            updateActiveRoute: this.updateActiveRoute,
            updateOpenMenu: this.updateOpenMenu,
            updateSelectedMenu: this.updateSelectedMenu,
            initMenu: this.initMenu,
            auth: this.state.auth,
            updateAuth: this.updateAuth,
          }}
        >
          {this.props.children}
        </GlobalContext.Provider>
      </React.Fragment>
    );
  }
}

export default GlobalState;
