import React, { PureComponent } from 'react';
import { Input, Form, Select } from 'antd';
import propTypes from 'prop-types';
import '../vehicle.less';

const { Option } = Select;
export class PageRendering extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className='vehicle-management-detail'>
        <Form.Item label='车牌号'>
          {getFieldDecorator('licensenumber')(
            <Input placeholder='请输入车牌号' />
          )}
        </Form.Item>
        <Form.Item label='所属城市'>
          {getFieldDecorator('city')(<Input placeholder='请输入所属城市' />)}
        </Form.Item>
        <Form.Item label='车辆类型'>
          {getFieldDecorator('drivertype')(
            <Select>
              <Option value='1'>内销车</Option>
              <Option value='2'>外部车辆</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label='车长(米)'>
          {getFieldDecorator('conductor')(
            <Input placeholder='请输入车长(米)' />
          )}
        </Form.Item>
        <Form.Item label='最大载重(吨)'>
          {getFieldDecorator('maximumpayload')(
            <Input placeholder='请输入最大载重(吨)' />
          )}
        </Form.Item>
      </div>
    );
  }
}

PageRendering.propTypes = {
  data: propTypes.object,
  form: propTypes.object, //必传
};
