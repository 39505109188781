import withAsyncComponent from '@/util/withAsyncComponent';
import VehicleDetail from '../../pages/logistics/vehicle/vehicleDetail';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const DriverLogistics = withAsyncComponent(() =>
  import('@/pages/logistics/driverlogistics/List')
); // 物流司机列表
const LogisticsDetail = withAsyncComponent(() =>
  import('@/pages/logistics/driverlogistics/Detail')
); // 物流司机详情
const DriverManagement = withAsyncComponent(() =>
  import('@/pages/logistics/driverManagement')
); // 司机管理
const DriverDetail = withAsyncComponent(() =>
  import('@/pages/logistics/driverManagement/driverDetail')
); // 司机管理编辑
const Vehicle = withAsyncComponent(() => import('@/pages/logistics/vehicle')); // 车辆管理
const vehicleDetail = withAsyncComponent(() =>
  import('@/pages/logistics/vehicle/vehicleDetail')
); // 车辆管理详情
const Waybill = withAsyncComponent(() =>
  import('@/pages/logistics/waybill/List')
); // 运单列表查询
const WaybillDetail = withAsyncComponent(() =>
  import('@/pages/logistics/waybill/Detail')
); // 运单查询详情
const OrderReportList = withAsyncComponent(() =>
  import('@/pages/logistics/orderReport/List')
); // 自助机订单报表
const OrderReportPrint = withAsyncComponent(() =>
  import('@/pages/logistics/orderReport/Print')
); // 自助机订单报表
const DispatchList = withAsyncComponent(() =>
  import('@/pages/logistics/dispatch/List')
); // 车辆调度列表
const DispatchDetail = withAsyncComponent(() =>
  import('@/pages/logistics/dispatch/Detail')
); // 车辆调度详情

const Logistics = [
  {
    path: '/logistics',
    name: '物流管理',
    key: 'logistics',
    icon: 'car',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/logistics/driverlogistics',
        name: '司机审核',
        exact: true,
        key: 'driverlogisticslist',
        Component: DriverLogistics,
      },
      {
        path: '/logistics/driverlogistics/detail/:id',
        name: '审核详情',
        key: 'driverlogisticsdetail',
        hideInMenu: true,
        Component: LogisticsDetail,
      },
      {
        path: '/logistics/driver',
        name: '司机管理',
        exact: true,
        key: 'driverLists',
        Component: DriverManagement,
      },
      {
        path: '/logistics/driver/add',
        name: '编辑司机',
        key: 'driverAdd',
        hideInMenu: true,
        Component: DriverDetail,
      },
      {
        path: '/logistics/driver/detail/:id',
        name: '编辑司机',
        key: 'driverEdit',
        hideInMenu: true,
        Component: DriverDetail,
      },
      {
        path: '/logistics/vehicle',
        name: '车辆管理',
        exact: true,
        key: 'vehicleList',
        Component: Vehicle,
      },
      {
        path: '/logistics/vehicle/add',
        name: '新增车辆',
        key: 'vehiclAdd',
        hideInMenu: true,
        Component: vehicleDetail,
      },
      {
        path: '/logistics/vehicle/edit/:id',
        name: '编辑车辆',
        key: 'vehiclEdit',
        hideInMenu: true,
        Component: vehicleDetail,
      },
      {
        path: '/logistics/waybill',
        exact: true,
        name: '运单查询',
        key: 'waybillist',
        Component: Waybill,
      },
      {
        path: '/logistics/waybill/detail/:id',
        name: '运单查询详情',
        key: 'waybilldetail',
        hideInMenu: true,
        Component: WaybillDetail,
      },
      {
        path: '/logistics/order/report',
        name: '自助订单管理',
        exact: true,
        key: 'orderReport',
        Component: OrderReportList,
      },
      {
        path: '/logistics/order/print',
        name: '自助订单占比导出',
        exact: true,
        key: 'orderReportPrint',
        Component: OrderReportPrint,
      },
      {
        path: '/logistics/dispath',
        name: '车辆调度', 
        key: 'logisticsDispath',
        routes: [
          {
            path: '/logistics/dispath',
            name: '车辆调度详情',
            exact: true,
            auth: false,
            hideInMenu: true,
            key: 'logisticsDispathDetail',
            Component: DispatchList,
          },
          {
            path: '/logistics/dispath/edit/:id',
            name: '车辆调度详情',
            exact: true,
            auth: false,
            hideInMenu: true,
            key: 'logisticsDispathList',
            Component: DispatchDetail,
          },
        ],
      },
    ],
  },
];

export { Logistics };
