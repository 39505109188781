import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const FeedCommodityList = withAsyncComponent(() =>
  import('@/pages/feedcommodity/List')
); // 饲料商品列表
const FeedCommodityEdit = withAsyncComponent(() =>
  import('@/pages/feedcommodity/EditFeedCommodity')
); // 饲料商品编辑

const feedCommodityRoute = [
  {
    path: '/feedcommodity',
    name: '饲料商品管理',
    key: 'feedcommodity',
    icon: 'pie-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/feedcommodity',
        exact: true,
        key: 'feedcommoditylist',
        Component: FeedCommodityList,
        hideInMenu: true,
      },
      {
        path: '/feedcommodity/edit/:id',
        exact: true,
        name:'编辑饲料商品',
        key: 'feedcommodityedit',
        Component: FeedCommodityEdit,
        hideInMenu: true,
      },
    ],
  },
];

export { feedCommodityRoute };
