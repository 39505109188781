import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));

const ClueAllotList = withAsyncComponent(() =>
  import('@/pages/crmReport/clueAllot/Index.js')
);

const ClueAllotListDrill = withAsyncComponent(() =>
  import('@/pages/crmReport/clueAllotDrill/Index.js')
);

const UsageList = withAsyncComponent(() =>
  import('@/pages/crmReport/usage/Index.js')
);
const UsageListDrill = withAsyncComponent(() =>
  import('@/pages/crmReport/usageDrill/Index.js')
);
const UsageListFinance = withAsyncComponent(() =>
  import('@/pages/crmReport/usageFinance/Index.js')
);
const UsageDetailDrill = withAsyncComponent(() =>
  import('@/pages/crmReport/usageDrill/Detail.js')
);

const UsageDetail = withAsyncComponent(() =>
  import('@/pages/crmReport/usage/Detail.js')
);

const crmReportRoute = [
  // {
  //   path: '/crm/report/usage',
  //   name: '猪博士CRM使用情况报表',
  //   key: 'crmUsage',
  //   icon: 'file-text',
  //   auth: true,
  //   login: true,
  //   Layout: BasicLayout,
  //   routes: [
  //     {
  //       hideInMenu: true,
  //       path: '/crm/report/usage',
  //       exact: true,
  //       key: 'crmUsageList',
  //       Component: UsageList,
  //     },
  //     {
  //       hideInMenu: true,
  //       name: '猪博士CRM使用情况详情',
  //       path: '/crm/report/usage/detail',
  //       exact: true,
  //       key: 'crmUsageDetail',
  //       Component: UsageDetail,
  //     },
  //   ],
  // },
  // {
  //   path: '/crm/report/clueallot',
  //   name: '猪博士CRM线索下发报表',
  //   key: 'crmClueAllot',
  //   icon: 'file-text',
  //   auth: true,
  //   login: true,
  //   Layout: BasicLayout,
  //   routes: [
  //     {
  //       hideInMenu: true,
  //       path: '/crm/report/clueallot',
  //       exact: true,
  //       key: 'crmClueAllotList',
  //       Component: ClueAllotList,
  //     },
  //   ],
  // },
  {
    path: '/crm/report/usagedrill',
    name: '猪博士CRM使用情况报表',
    key: 'crmUsageReport',
    icon: 'file-text',
    auth: true,
    login: true,
    authType: 'children',
    routes: [
      {
        path: '/crm/report/usagedrill/business',
        name: '业务追踪报表',
        key: 'crmUsage',
        icon: 'file-text',
        auth: true,
        login: true,
        Layout: BasicLayout,
        routes: [
          {
            hideInMenu: true,
            path: '/crm/report/usagedrill/business',
            exact: true,
            key: 'crmUsageDrillList',
            Component: UsageListDrill,
          },
          {
            hideInMenu: true,
            name: '业务追踪报表详情',
            path: '/crm/report/usagedrill/business/detail',
            exact: true,
            key: 'crmUsageDetailDrill',
            Component: UsageDetailDrill,
          },
        ],
      },
      {
        path: '/crm/report/usagedrill/finance',
        name: '财务追踪报表',
        key: 'crmUsageFinance',
        icon: 'file-text',
        auth: true,
        login: true,
        Layout: BasicLayout,
        routes: [
          {
            hideInMenu: true,
            path: '/crm/report/usagedrill/finance',
            exact: true,
            key: 'crmUsageFinanceList',
            Component: UsageListFinance,
          },
        ],
      },
    ],
  },
  {
    path: '/crm/report/clueallotdrill',
    name: '猪博士CRM线索下发报表',
    key: 'crmClueAllot',
    icon: 'file-text',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        hideInMenu: true,
        path: '/crm/report/clueallotdrill',
        exact: true,
        key: 'crmClueAllotList',
        Component: ClueAllotListDrill,
      },
    ],
  },
];

export default crmReportRoute;
