import React from 'react';
import './index.less';

export default function (props) {
  return (
    <div className="cp-card" {...props}>
      {props.title ? <div className="cp-card-title">{props.title}</div> : null}
      {props.children}
    </div>
  );
}
