import send from '../../../util/send';
import { PROMOTEPATH } from '../../../config/config';

// 获取司机物流列表
export function getDriverUserListApi(params) {
  return send({ url: PROMOTEPATH + '/admin/driver/index', params });
}
// 获取司机物流详情
export function getDriverDetailApi(params) {
  return send({ url: PROMOTEPATH + '/admin/driver/info', params });
}
// 获取司机物流提交
export function getDriverSubmitApi(data) {
  return send({
    url: PROMOTEPATH + '/admin/driver/audit',
    method: 'POST',
    data,
  });
}

