import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Index = withAsyncComponent(() =>
  import('@/pages/feeduncooperatecustomer/Index')
);
const Detail = withAsyncComponent(() =>
  import('@/pages/feeduncooperatecustomer/Detail')
);

export default [
  {
    path: '/feeduncooperatecustomer',
    name: '非合作客户管理',
    key: 'feeduncooperatecustomer',
    icon: 'pie-chart',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/feeduncooperatecustomer',
        exact: true,
        name: '非合作客户列表',
        key: 'feeduncooperatecustomerlist',
        Component: Index,
        hideInMenu: true,
      },
      {
        path: '/feeduncooperatecustomer/edit/:id',
        name: '非合作客户详情',
        key: 'feeduncooperatecustomeredit',
        Component: Detail,
        hideInMenu: true,
      }
    ],
  },
];
