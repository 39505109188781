import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Company = withAsyncComponent(() => import('@/pages/company/Company'));
const CompanyDetail = withAsyncComponent(() =>
  import('@/pages/company/CompanyDetail')
);
const Pigtrading = withAsyncComponent(() =>
  import('@/pages/pigtrading/Pigtrading')
);
const PigtradingDetail = withAsyncComponent(() =>
  import('@/pages/pigtrading/PigtradingDetail')
);
const PigtradingOrder = withAsyncComponent(() =>
  import('@/pages/pigtrading/PigtradingOrder')
);
const CarRecordList = withAsyncComponent(() =>
  import('@/pages/pigtrading/carRecord/List')
);
const CarRecordDetail = withAsyncComponent(() =>
  import('@/pages/pigtrading/carRecord/Detail')
);

const PigTrade = [
  {
    path: '/company',
    name: '猪交易公司管理',
    key: 'company',
    icon: 'calendar',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/company',
        exact: true,
        key: 'companyList',
        Component: Company,
        hideInMenu: true,
      },
      {
        path: '/company/edit/:id',
        name: '编辑公司',
        key: 'companyEdit',
        Component: CompanyDetail,
        hideInMenu: true,
      },
      {
        path: '/company/add',
        name: '新增公司',
        key: 'companyAdd',
        Component: CompanyDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/user',
    name: '猪交易用户管理',
    key: 'pigTrading',
    icon: 'contacts',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/user',
        exact: true,
        key: 'pigTradingUser',
        Component: Pigtrading,
        hideInMenu: true,
      },
      {
        path: '/user/add',
        name: '新增猪交易用户',
        key: 'pigTradingAdd',
        Component: PigtradingDetail,
        hideInMenu: true,
      },
      {
        path: '/user/edit/:id',
        name: '编辑猪交易用户',
        key: 'pigTradingEdit',
        Component: PigtradingDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/pigtrading/order',
    name: '猪交易订单管理',
    key: 'pigTradingOrder',
    icon: 'contacts',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/pigtrading/order',
        exact: true,
        key: 'pigTradingOrder',
        Component: PigtradingOrder,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/pigtrading/carRecord',
    name: '猪交易车辆备案',
    key: 'carRecord',
    icon: 'car',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/pigtrading/carRecord',
        exact: true,
        key: 'carRecordList',
        Component: CarRecordList,
        hideInMenu: true,
      },
      {
        path: '/pigtrading/carRecord/detail/:id',
        name: '新增猪交易车辆',
        key: 'carRecordDetail',
        Component: CarRecordDetail,
        hideInMenu: true,
      },
    ],
  },
];

export { PigTrade };
