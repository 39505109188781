import withAsyncComponent from "@/util/withAsyncComponent";
const BasicLayout = withAsyncComponent(() => import("@/layout/BasicLayout"));
const MedicineList = withAsyncComponent(() => import("@/pages/medicine/medicineList/MedicineList"));
const EditMedicine = withAsyncComponent(() => import("@/pages/medicine/medicineList/EditMedicine"));
const MedicineCustomerList = withAsyncComponent(() => import('@/pages/medicine/customer/CustomerList'));
const MedicineCustomerEdit = withAsyncComponent(() => import('@/pages/medicine/customer/CustomerEdit'));
const MedicineOrderList = withAsyncComponent(() => import('@/pages/medicine/medicineOrder/MedicineOrderList'));
const MedicineOrderDetail = withAsyncComponent(() => import('@/pages/medicine/medicineOrder/MedicineOrderDetail'));
const MedicineCustomerClass = withAsyncComponent(() => import('@/pages/medicine/customerClass/CustomerClass'));
// 如果菜单要分组，路由结构要改 listPageParams.js 文件要改，因为现在不支持菜单分组
const medicineRoutes = [
  {
    path: '/medicine/list',
    name: '兽药商品管理',
    key: 'medicineListMenu',
    icon: 'medicine-box',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/medicine/list',
        exact: true,
        key: 'medicineListPage',
        Component: MedicineList,
        hideInMenu: true
      },
      {
        path: '/medicine/list/edit/:id',
        key: 'editMedicine',
        Component: EditMedicine,
        hideInMenu: true,
        name: '编辑药品信息'
      }
    ]
  },
  {
    path: '/medicine/customers',
    name: '兽药客户管理',
    key: 'medicineCustomersMenu',
    icon: 'team',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/medicine/customers',
        exact: true,
        key: 'medicineCustomersPage',
        Component: MedicineCustomerList,
        hideInMenu: true
      },
      {
        path: '/medicine/customers/edit/:id',
        key: 'medicineCustomersEditPage',
        Component: MedicineCustomerEdit,
        hideInMenu: true,
        name: '编辑客户信息'
      }
    ]
  },
  {
    path: '/medicine/customerclass',
    name: '兽药客户分类管理',
    key: 'medicineCustomerClassMenu',
    icon: 'team',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/medicine/customerclass',
        exact: true,
        key: 'medicineCustomerClassPage',
        Component: MedicineCustomerClass,
        hideInMenu: true,
      }
    ]
  },
  {
    path: '/medicine/order',
    name: '兽药订单管理',
    key: 'medicineOrderMenu',
    icon: 'file-text',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/medicine/order',
        exact: true,
        key: 'medicineOrderListPage',
        Component: MedicineOrderList,
        hideInMenu: true,
      },
      {
        path: '/medicine/order/detail/:id',
        key: 'medicineOrderDetailPage',
        Component: MedicineOrderDetail,
        hideInMenu: true,
        name: '兽药订单详情'
      }
    ]
  }
]
export {medicineRoutes}
