import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Company = withAsyncComponent(() => import('@/pages/company/Company'));

const GoodsIndex = withAsyncComponent(() =>
    import('@/pages/goodsAndOrderService/goods/Index')
);
const GoodsDetail = withAsyncComponent(() =>
    import('@/pages/goodsAndOrderService/goods/Detail')
);

const OrderIndex = withAsyncComponent(() =>
    import('@/pages/goodsAndOrderService/order/Index')
);
const OrderDetail = withAsyncComponent(() =>
    import('@/pages/goodsAndOrderService/order/Detail')
);

const goodsAndOrderService = [
    {
        path: '/goodsAndOrderService/goods',
        name: '服务商品管理',
        key: 'goodsServe',
        icon: 'heart',
        auth: true,
        login: true,
        Layout: BasicLayout,
        routes: [
            {
                path: '/goodsAndOrderService/goods',
                exact: true,
                key: 'goodsService',
                Component: GoodsIndex,
                hideInMenu: true,
            },
            {
                path: '/goodsAndOrderService/goods/detail/:id',
                name: '新增商品',
                key: 'goodsDetail',
                Component: GoodsDetail,
                hideInMenu: true,
            },
        ],
    },
    {
        path: '/goodsAndOrderService/order',
        name: '服务订单管理',
        key: 'orderServe',
        icon: 'heart',
        auth: true,
        login: true,
        Layout: BasicLayout,
        routes: [
            {
                path: '/goodsAndOrderService/order',
                exact: true,
                key: 'orderServeList',
                Component: OrderIndex,
                hideInMenu: true,
            },
            {
                path: '/goodsAndOrderService/order/detail/:id',
                name: '订单详情',
                key: 'orderDetail',
                Component: OrderDetail,
                hideInMenu: true,
            },
        ],
    },
];

export { goodsAndOrderService };