import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const Organization = withAsyncComponent(() =>
  import('@/pages/system/organization/Index')
);
const OrganizationDetail = withAsyncComponent(() =>
  import('@/pages/system/organization/Detail')
);
const Department = withAsyncComponent(() =>
  import('@/pages/system/department/Index')
);
const DepartmentDetail = withAsyncComponent(() =>
  import('@/pages/system/department/Detail')
);
const Employee = withAsyncComponent(() =>
  import('@/pages/system/employee/Index')
);
const EmployeeDetail = withAsyncComponent(() =>
  import('@/pages/system/employee/Detail')
);


export default [
  {
    path: '/system/organization',
    name: '组织结构管理',
    key: 'organization',
    icon: 'apartment',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/system/organization',
        exact: true,
        name: '组织列表',
        key: 'organizationList',
        Component: Organization,
        hideInMenu: true,
      },
      {
        path: '/system/organization/add',
        exact: true,
        name: '新建组织',
        key: 'organizationAdd',
        Component: OrganizationDetail,
        hideInMenu: true,
      },
      {
        path: '/system/organization/edit/:id',
        exact: true,
        name: '编辑组织',
        key: 'organizationEdit',
        Component: OrganizationDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/system/department',
    name: '部门管理',
    key: 'department',
    icon: 'table',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/system/department',
        exact: true,
        name: '部门列表',
        key: 'departmentList',
        Component: Department,
        hideInMenu: true,
      },
      {
        path: '/system/department/add',
        exact: true,
        name: '添加部门',
        key: 'departmentAdd',
        Component: DepartmentDetail,
        hideInMenu: true,
      },
      {
        path: '/system/department/edit/:id',
        exact: true,
        name: '编辑部门',
        key: 'departmentEdit',
        Component: DepartmentDetail,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/system/employee',
    name: '员工管理',
    key: 'employee',
    icon: 'idcard',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/system/employee',
        exact: true,
        name: '员工列表',
        key: 'employeeList',
        Component: Employee,
        hideInMenu: true,
      },
      {
        path: '/system/employee/add',
        exact: true,
        name: '新建员工',
        key: 'employeeAdd',
        Component: EmployeeDetail,
        hideInMenu: true,
      },
      {
        path: '/system/employee/edit/:id',
        exact: true,
        name: '编辑员工',
        key: 'employeeEdit',
        Component: EmployeeDetail,
        hideInMenu: true,
      }
    ],
  },
];
