import withAsyncComponent from '@/util/withAsyncComponent';
const BasicLayout = withAsyncComponent(() => import('@/layout/BasicLayout'));
const visitRecord = withAsyncComponent(() =>
  import('@/pages/visit/VisitRecord')
); 
const TargetRecord = withAsyncComponent(() =>
  import('@/pages/visit/TargetRecord')
); 

const TargetRoute = [
  {
    path: '/visit/record',
    name: '拜访记录查询',
    key: 'visit',
    icon: 'medicine-box',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/visit/record',
        exact: true,
        key: 'visitRecordList',
        Component: visitRecord,
        hideInMenu: true,
      },
    ],
  },
  {
    path: '/target/record',
    name: '目标记录查询',
    key: 'target',
    icon: 'medicine-box',
    auth: true,
    login: true,
    Layout: BasicLayout,
    routes: [
      {
        path: '/target/record',
        exact: true,
        key: 'targetRecordList',
        Component: TargetRecord,
        hideInMenu: true,
      },
    ],
  },
];


export default TargetRoute;
